/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  value: string;
  setColor: (color: string) => void;
}

const ColorPicker = templateOnlyComponent<Args>();
export default ColorPicker;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Composer::HelpCenterFooterEditor::ColorPicker': typeof ColorPicker;
    'composer/help-center-footer-editor/color-picker': typeof ColorPicker;
  }
}
