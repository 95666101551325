/* import __COLOCATED_TEMPLATE__ from './reinstall-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';

export default Component.extend({
  isReinstalling: false,
  reinstallConfirmValue: '',
  canReinstall: equal('reinstallConfirmValue', 'REINSTALL'),
  isProtectedAppPackage: readOnly('appPackage.isProtectedAppPackage'),

  confirm: action(function () {
    this.set('isReinstalling', true);
    return this.reinstall.perform();
  }),
});
