/* import __COLOCATED_TEMPLATE__ from './video-embed.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class VideoEmbed extends Component {
  @service intercomEventService;
  @tracked mouseOverVideo = false;

  @action
  onWindowBlur() {
    if (this.mouseOverVideo) {
      this.intercomEventService.trackAnalyticsEvent({
        object: this.args.appPackage,
        action: 'video_click',
      });
    }
  }
}
