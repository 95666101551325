/* import __COLOCATED_TEMPLATE__ from './onboarding-container-component.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';

export default class AppStoreAppPackageOnboardingContainerComponent extends Component {
  @service store;
  @service appService;
  @service appstoreService;
  @service intercomEventService;

  @tracked inboxSidebarAppIds = [];
  @tracked openSectionId = this.defaultOpenedSection;

  constructor() {
    super(...arguments);
    if (this.args.appPackage.worksWithInboxSidebar) {
      this._loadInboxAppIdsUsedInSidebar.perform();
    }
  }

  @task *_loadInboxAppIdsUsedInSidebar() {
    let allSidebarSectionsForAdmin = EmbercomBootDataModule.isBootedInPublicAppStoreMode()
      ? { sections: [] }
      : yield this.store.queryRecord('inbox/admin-app-sidebar-section-list', {
          filter: {
            appId: this.appService.app.id,
            adminId: this.appService.app.currentAdmin.id,
          },
        });

    this.inboxSidebarAppIds = allSidebarSectionsForAdmin.sections.reduce((accum, section) => {
      if (section.inboxAppId) {
        accum.push(section.inboxAppId);
      }
      return accum;
    }, []);
  }

  get usedInAdminSidebar() {
    return this.inboxSidebarAppIds.includes(this.args.appPackage.inboxAppId);
  }

  get startingGuide() {
    return this.args.appPackage.startingGuideBlocks;
  }

  get defaultOpenedSection() {
    return isEmpty(this.startingGuide) ? 'capabilities' : 'guide';
  }

  @action trackOpenSection(sectionName) {
    this.trackAnalyticsEvent('open', `app-onboarding-section_${sectionName}`, {
      app_package_code: this.args.appPackage.id,
    });
  }

  @action trackCloseSection(sectionName) {
    this.trackAnalyticsEvent('close', `app-onboarding-section_${sectionName}`, {
      app_package_code: this.args.appPackage.id,
    });
  }

  @action trackAnalyticsEvent(eventAction, eventObject, extras = {}) {
    this.intercomEventService.trackAnalyticsEvent({
      action: eventAction,
      object: eventObject,
      ...extras,
    });

    let { email_request } = this.appstoreService.getRedirectQueryParams();
    if (email_request) {
      let emailRequestAction = `${email_request}_${eventAction}`;
      this.intercomEventService.trackAnalyticsEvent({
        object: eventObject,
        action: emailRequestAction,
        ...extras,
      });
    }
  }
}
