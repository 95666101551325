/* import __COLOCATED_TEMPLATE__ from './fin-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import { CustomerAnswerRating, type PlaygroundTestQuestion } from 'embercom/lib/fin-playground';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';

interface FinAnswerComponentArgs {
  isRunning: boolean;
  appId: string;
  question?: PlaygroundTestQuestion;
  onAddNewContent: () => void;
  onUpdateQuestionRating: (questionId: number, rating: CustomerAnswerRating) => void;
  isUpdatingAnswerRating: boolean;
}

type FinAnswerComponentSignature = {
  Args: FinAnswerComponentArgs;
  Element: HTMLDivElement;
};

export default class AiAgentPlaygroundFinAnswer extends Component<FinAnswerComponentSignature> {
  CustomerAnswerRating = CustomerAnswerRating;

  get answer() {
    return this.args.question?.responseText;
  }

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get shouldMarkAsPositive() {
    if (this.args.question?.customerAnswerRating === CustomerAnswerRating.Negative) {
      return false;
    }

    return (
      this.args.question?.customerAnswerRating === CustomerAnswerRating.Positive ||
      !this.args.question?.isNotDirectAnswer
    );
  }

  get shouldDisableFeedbackButtons() {
    return this.args.isRunning || this.args.isUpdatingAnswerRating;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer': typeof AiAgentPlaygroundFinAnswer;
  }
}
