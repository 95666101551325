/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  openCenteredWindowService: service(),
  router: service(),
  permissionsService: service(),

  canImportUsers: computed(function () {
    return this.permissionsService.currentAdminCan('can_bulk_import_contacts');
  }),

  actions: {
    import() {
      let url = this.router.urlFor('apps.app.import.mixpanel');
      this.openCenteredWindowService.setup(url, 800, 1100, 'Import');
    },
  },
});
