/* import __COLOCATED_TEMPLATE__ from './attribute-float-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import rawValueToFilterValue from 'embercom/lib/users/user-company-list/raw-value-to-filter-value';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';

export default class AttributePlanComponent extends Component {
  get value() {
    let value = this.isNotBlank ? this.rawValue : 'Unknown';
    if (this.args.attribute.isCurrencyType) {
      return `$${value}.00`;
    }
    return value;
  }

  get isNotBlank() {
    let userOrCompany = this.args.userOrCompany;
    if (this.args.attribute && userOrCompany) {
      return !isBlank(this.rawValue);
    }
    return false;
  }

  get textClass() {
    return this.isNotBlank ? '' : 'text-muted';
  }

  get rawValue() {
    return this.args.userOrCompany.getValue(this.args.attribute.identifier);
  }

  get filterValue() {
    return rawValueToFilterValue(this.args.attribute.identifier, this.value);
  }

  get filterUrl() {
    return B64FilterParamEncoder.filterUrl(
      this.args.userOrCompany,
      this.args.attribute,
      this.filterValue,
    );
  }

  get tooltipText() {
    return `${this.value}`;
  }
}
