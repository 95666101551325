/* import __COLOCATED_TEMPLATE__ from './message-button.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import buildCompanyPredicates from 'embercom/lib/users/user-company-list/company-predicate-builder';

export default class MessageButton extends Component {
  @service router;
  @service store;

  get templates() {
    let templates = this.store.peekAll('template');
    if (templates.length > 0) {
      return templates;
    }
    return this.store.findAll('template');
  }

  @action
  createContent(objectType, _null1, _null2, templateId) {
    let rulesetParams = {
      object_type: objectType,
      predicate_group: { predicates: buildCompanyPredicates([this.args.company]) },
    };
    if (templateId) {
      rulesetParams.template_id = templateId;
    }
    return this.router.transitionTo('apps.app.content.new', rulesetParams);
  }
}
