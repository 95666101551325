/* import __COLOCATED_TEMPLATE__ from './install-message-unhealthy-status.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { computed, action } from '@ember/object';

export const INSTALL_STATUS_CTA_TYPES = {
  urlCta: 'URL_CTA',
  reinstallCta: 'REINSTALL_CTA',
};

export default Component.extend({
  tagName: '',

  hasReinstallCta: computed('installStatusCtaType', function () {
    let { installStatusCtaType } = this;
    return installStatusCtaType === INSTALL_STATUS_CTA_TYPES.reinstallCta;
  }),

  isCtaAvailable: computed('installStatusCtaType', function () {
    let { installStatusCtaType } = this;
    return Boolean(installStatusCtaType);
  }),

  openCtaUrl: action(function () {
    safeWindowOpen(this.installStatusCtaUrl);
  }),
});
