/* import __COLOCATED_TEMPLATE__ from './common-primary-nav-bar.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';
import RouteRegexes from 'embercom/lib/route-regexes';
import type CommonPrimaryNavBarService from 'embercom/services/common-primary-nav-bar-service';
import { NAV_BAR_EXPANDED_WIDTH_PX } from 'embercom/services/common-primary-nav-bar-service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';
import type InboxState from 'embercom/services/inbox-state';
import { SidebarViewType } from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';

type NavBarEntry = {
  id: string;
  productName: string;
  route: string;
  icon?: InterfaceIconName;
  activeRoutes: string[];
  className?: string;
  analyticsEventObjectName?: string;
  dataIntercomTarget?: string;
  canUseProduct: boolean;
};

interface Args {
  app: $TSFixMe;
  isChannelSwitcherActive: boolean;
  canSeeNewIASettingsHub: boolean;
  inboxCount: number;
  canUseInbox: boolean;
  canUseOutbound: boolean;
  isWorkspaceEnabled: boolean;
  inInbox?: boolean;
  disableUserMenu?: boolean;
}

interface Signature {
  Args: Args;
}

const COMMON_PRIMARY_NAV = 'common-primary-nav';

export default class CommonPrimaryNavBar extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare commonPrimaryNavBarService: CommonPrimaryNavBarService;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare inboxState: InboxState;
  @service declare navbarCollapsingService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare session: Session;

  @tracked navItemInFocus: string | null = null;
  @tracked expandNavbar = false;
  @tracked fullyExpanded = false;
  @tracked userMenuOpen = false;
  @tracked timeout: ReturnType<typeof setTimeout> | null = null;

  constructor(owner: $TSFixMe, args: Args) {
    super(owner, args);
    this.commonPrimaryNavBarService.initialise(this.workspaceID);
    this.fullyExpanded =
      this.commonPrimaryNavBarService.pinned ||
      this.router.currentRouteName.startsWith('apps.app.getting-started');
    this.router.on('routeWillChange', () => {
      if (this.onInboxRoute) {
        this.setWidgetVisibility(false);
      }
    });
  }

  sidebar = modifier(
    (navBar: Element) => {
      let onTrasitionStart = () => {
        this.fullyExpanded = false;
      };

      let onTransitionEnd = (event: TransitionEvent) => {
        let width = (event.target as HTMLDivElement).offsetWidth;
        if (width === NAV_BAR_EXPANDED_WIDTH_PX) {
          this.fullyExpanded = true;
        }
      };
      navBar.addEventListener('transitionstart', onTrasitionStart);
      navBar.addEventListener('transitionend', onTransitionEnd);

      return function () {
        navBar.removeEventListener('transitionstart', onTrasitionStart);
        navBar.removeEventListener('transitionend', onTransitionEnd);
      };
    },
    { eager: false },
  );

  get workspaceID() {
    return this.args.app.id;
  }

  get canSeeSecondaryNav() {
    return this.onInboxRoute
      ? this.inboxState.activeSidebarView !== SidebarViewType.Hidden
      : !this.navbarCollapsingService.collapsed;
  }

  get onInboxRoute() {
    return (
      this.router.currentRouteName.match(RouteRegexes.inbox) ||
      this.router.currentRouteName.match(RouteRegexes.inbox2) ||
      this.router.currentRouteName.startsWith('inbox_loading')
    );
  }

  get shouldShowRadialIndicator() {
    return (
      (this.totalSteps !== this.completedSteps &&
        this.router.currentRouteName.match(RouteRegexes.gettingStarted)) ||
      (!this.greatGuidanceService.hideFloatingWidget &&
        (!this.greatGuidanceService.widgetVisibility || !this.canSeeSecondaryNav))
    );
  }

  get totalSteps() {
    return this.greatGuidanceService.steps.length;
  }

  get completedSteps() {
    return this.greatGuidanceService.steps.filter((step) => step.state === 'completed').length;
  }

  get greatGuidanceWidgetEntry(): NavBarEntry {
    return {
      id: 'getting-started',
      productName: this.intl.t('components.primary-nav.get-started'),
      route: 'apps.app.getting-started',
      activeRoutes: ['apps.app.getting-started'],
      analyticsEventObjectName: 'getting_started_home_primary_nav_icon',
      dataIntercomTarget: 'data-test-guide-library-link',
      canUseProduct: true,
    };
  }

  get settingsEntry(): NavBarEntry {
    return {
      id: 'settings',
      icon: 'settings',
      productName: this.intl.t('components.primary-nav.settings'),
      route: 'apps.app.settings',
      activeRoutes: ['apps.app.settings'],
      analyticsEventObjectName: 'product_settings_primary_nav_icon',
      dataIntercomTarget: 'ia-settings-nav-item',
      canUseProduct: true,
    };
  }

  get topNavEntries(): NavBarEntry[] {
    return [
      {
        id: 'inbox',
        icon: 'inbox',
        productName: this.intl.t('components.primary-nav.inbox'),
        route: 'apps.app.inbox',
        activeRoutes: ['inbox.workspace.inbox'],
        canUseProduct: this.args.canUseInbox,
        dataIntercomTarget: 'pia-nav-inbox',
      },
      {
        id: 'ai-automation',
        icon: 'fin',
        productName: this.intl.t('components.primary-nav.ai-automation'),
        route: 'apps.app.automation.fin-ai-agent.setup',
        activeRoutes: ['apps.app.automation'],
        canUseProduct: true,
        dataIntercomTarget: 'pia-nav-ai-automation',
      },
      {
        id: 'knowledge-hub',
        icon: 'article',
        productName: this.intl.t('components.primary-nav.knowledge-base'),
        route: 'apps.app.knowledge-hub',
        activeRoutes: ['apps.app.knowledge-hub'],
        canUseProduct: true,
        dataIntercomTarget: 'pia-nav-knowledge-hub',
      },
      {
        id: 'reports',
        icon: 'bar-charts',
        productName: this.intl.t('components.primary-nav.reports'),
        route: 'apps.app.reports',
        activeRoutes: ['apps.app.reports'],
        analyticsEventObjectName: 'reporting_side_bar_link',
        canUseProduct: true,
        dataIntercomTarget: 'pia-nav-reports',
      },
      {
        id: 'outbound',
        icon: 'outbound-filled',
        productName: this.intl.t('components.primary-nav.outbound-ia'),
        route: 'apps.app.outbound',
        activeRoutes: ['apps.app.outbound'],
        canUseProduct: this.args.canUseOutbound,
        dataIntercomTarget: 'pia-nav-outbound',
      },
      {
        id: 'contacts',
        icon: 'multiple-people',
        productName: this.intl.t('components.primary-nav.contacts'),
        route: 'apps.app.users',
        activeRoutes: ['apps.app.users', 'apps.app.companies'],
        canUseProduct: true,
        dataIntercomTarget: 'pia-nav-contacts',
      },
    ];
  }

  get homeButtonRoute() {
    return this.showExpiredSubscriptionNavBar ? 'apps.app.expired-subscription' : 'apps.app.home';
  }

  get showExpiredSubscriptionNavBar() {
    return !!this.args.app?.canDisplayExpiredSubscriptionPage;
  }

  get showPinButton() {
    return (
      !this.showExpiredSubscriptionNavBar &&
      ((this.fullyExpanded && this.navBarExpanded) || this.navBarPinned) &&
      !this.router.currentRouteName.startsWith('apps.app.getting-started')
    );
  }

  get navBarPinned() {
    return (
      this.commonPrimaryNavBarService.pinned ||
      this.router.currentRouteName.startsWith('apps.app.getting-started')
    );
  }

  get navBarExpanded() {
    return this.expandNavbar || this.navBarPinned || this.userMenuOpen;
  }

  get inboxCount() {
    return this.args.inboxCount > 99 ? '99+' : this.args.inboxCount;
  }

  @action
  currentActiveRoute(routes: string[]) {
    return routes.some((route) => this.router.currentRouteName.startsWith(route));
  }

  @action
  handleMouseHover(expand: boolean) {
    this.timeout && clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.expandNavbar = expand;
      this.setWidgetVisibility(expand);
      this.timeout && clearTimeout(this.timeout);
    }, 200);
  }

  @action
  onMouseEnterNavItem(productId: string) {
    this.navItemInFocus = productId;
  }

  @action
  onMouseLeaveNavItem() {
    this.navItemInFocus = null;
  }

  @action
  handlePinNavBar() {
    this.expandNavbar = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: this.commonPrimaryNavBarService.pinned ? 'unpinned' : 'pinned',
      place: 'new-nav-bar',
    });
    this.commonPrimaryNavBarService.toggleNavBarPinStatus();
  }

  @action
  onPopoverShow() {
    this.userMenuOpen = true;
  }

  @action
  onPopoverHide() {
    this.userMenuOpen = false;
  }

  @action
  setWidgetVisibility(expand: boolean) {
    let widgetVisibility = !expand;
    if (this.commonPrimaryNavBarService?.pinned) {
      widgetVisibility = true;
    } else if (!this.commonPrimaryNavBarService?.pinned && expand) {
      widgetVisibility = false;
    }
    this.greatGuidanceService?.setWidgetVisibility(COMMON_PRIMARY_NAV, widgetVisibility);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CommonPrimaryNavBar: typeof CommonPrimaryNavBar;
    'common-primary-nav-bar': typeof CommonPrimaryNavBar;
  }
}
