/* import __COLOCATED_TEMPLATE__ from './company-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { reads } from '@ember/object/computed';
import { equalToProperty } from '@intercom/pulse/lib/computed-properties';
import UserOrCompaniesListComponent from 'embercom/components/base/user-or-companies-list';
import Company from 'embercom/models/company';
import { inject as service } from '@ember/service';

export default UserOrCompaniesListComponent.extend({
  attributeService: service(),
  displayedAttributes: reads('attributeService.displayedCompanyAttributes'),
  isShowingAll: equalToProperty('totalCount', 'app.company_count'),

  loadPage(page) {
    return this.executeSearch(Company, 'companies', { page });
  },

  tagTypeLabel: 'company',
  tagEndpoint: '/ember/companies/bulk_tag.json',

  actions: {
    showUserOrCompany(company) {
      this.router.transitionTo('apps.app.companies.company', company.id);
    },
    import() {},
    showCreateUserModal() {},
  },
});
