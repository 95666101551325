/* import __COLOCATED_TEMPLATE__ from './app-package.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { getOwner } from '@ember/application';
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ajax, { get } from 'embercom/lib/ajax';
import { and, equal, not, readOnly, empty } from '@ember/object/computed';
import { APP_PACKAGE_TABS } from 'embercom/lib/app-store/app-package-constants';
import { isEmpty } from '@ember/utils';

export const SALESFORCE_APP_LAST_ERROR_SEEN_AT_KEY = 'salesforce-app-last-error-seen-at';

export const SELECTED_AUTOMATIONS_APP_PACKAGE_CODES = [
  'jira-for-tickets',
  'github',
  'salesforce-by-intercom',
  'slack-for-intercom',
  'hubspot',
  'marketo_enrichment',
];

export default Component.extend({
  intl: service(),
  intercomEventService: service(),
  notificationsService: service(),
  store: service(),
  appService: service(),
  appstoreService: service(),
  redirectService: service(),
  router: service(),
  permissionsService: service(),
  onboardingHomeExternalStepService: service(),
  guideLibraryService: service(),
  tagName: '',
  app: readOnly('appService.app'),
  onPublicAppStore: empty('app'),
  isMobile: false,

  init() {
    this._super(...arguments);

    this.updateSelectedTab();

    let { install_success, error_message } = this.appstoreService.getRedirectQueryParams();

    if (!(install_success === undefined || install_success === null)) {
      this.sendInstallNotification(install_success, error_message);
      this._completeOnboardingStep();
    } else if (!(error_message === undefined || error_message === null)) {
      this.sendErrorNotification(error_message);
    }
    this.set('initialInstallState', {
      installState: this.appPackage.installState,
      installStateMessage: this.appPackage.installStateMessage,
      installStateCtaUrl: this.appPackage.installStateCtaUrl,
      installStateCtaLabel: this.appPackage.installStateCtaLabel,
      installStateCtaType: this.appPackage.installStateCtaType,
    });
    this.fetchSettings.perform();
    this.orchestrateSyncErrorsNotificationIndicator.perform();
  },

  didInsertElement() {
    this._super(...arguments);
    this._trackEmailRedirectAction('load');
  },

  selectedTab: null,
  showSettings: equal('selectedTab', APP_PACKAGE_TABS.settings),
  hideSettings: not('showSettings'),
  showHealth: equal('selectedTab', APP_PACKAGE_TABS.health),
  hideHealth: not('showHealth'),
  showOnboarding: equal('selectedTab', APP_PACKAGE_TABS.onboarding),
  hideOnboarding: not('showOnboarding'),
  showDescription: and('hideSettings', 'hideHealth', 'hideOnboarding'),

  adminPermitted: computed('permissionsService', function () {
    return this.permissionsService.currentAdminCan('can_manage_apps_and_integrations');
  }),

  adminNotPermitted: not('adminPermitted'),
  installConfirmationText: computed('intl.locale', 'appPackage.name', function () {
    return this.intl.t('appstore.app-package.successfully-installed', {
      appPackageName: this.appPackage.name,
    });
  }),
  installErrorText: computed('intl.locale', 'appPackage.name', function () {
    return this.intl.t('appstore.app-package.error-occurred', {
      appPackageName: this.appPackage.name,
    });
  }),
  isNotInstalled: not('appPackage.isInstalled'),
  isNotUnderMaintenance: not('appPackage.isUnderMaintenance'),

  canShowSettings: and('appPackage.isInstalled', 'settingsComponent', 'isNotUnderMaintenance'),
  canShowHealth: and('appPackage.isInstalled', 'healthComponent', 'isNotUnderMaintenance'),

  canEditSettings: computed(
    'canShowSettings',
    'appPackage.hasTeammateSettings',
    'adminPermitted',
    function () {
      return this.canShowSettings && (this.appPackage.hasTeammateSettings || this.adminPermitted);
    },
  ),

  canShowLinkToSettings: and('canEditSettings', 'canShowAppOnboarding'),

  defaultPublicategories: computed('appPackage.publicCategories.[]', function () {
    let categories = this.get('appPackage.publicCategories') || [];
    return categories.filter((category) => category.categoryType === 'default');
  }),

  shouldFetchSettingsModel: and('canEditSettings', 'settingsModel'),

  settingsModel: computed('settingsModelPath', function () {
    return getOwner(this).factoryFor(`model:${this.settingsModelPath}`);
  }),

  settingsComponent: computed('settingsComponentPath', function () {
    return getOwner(this).factoryFor(`component:${this.settingsComponentPath}`);
  }),

  settingsModelPath: computed('appPackage.implementationIdentifier', function () {
    return `appstore/app-package/${this.appPackage.implementationIdentifier}/settings`;
  }),

  settingsComponentPath: computed('appPackage.implementationIdentifier', function () {
    return `appstore/app-package/settings/${this.appPackage.implementationIdentifier}/main`;
  }),

  healthComponent: computed('healthComponentPath', function () {
    return getOwner(this).factoryFor(`component:${this.healthComponentPath}`);
  }),

  healthComponentPath: computed('appPackage.implementationIdentifier', function () {
    return `appstore/app-package/health/${this.appPackage.implementationIdentifier}/main`;
  }),

  worksWithInboxSidebar: readOnly('appPackage.capabilities.works_with_inbox_sidebar'),

  canShowAppOnboarding: computed(
    'appPackage.isInstalled',
    'appPackage.hasCapabilities',
    'appPackage.startingGuideBlocks',
    function () {
      let { isInstalled, hasCapabilities, hasStartingGuide } = this.appPackage;
      return isInstalled && (hasCapabilities || hasStartingGuide);
    },
  ),

  doesNotHavePricingModel: not('appPackage.pricingModel'),

  canShowPricingDetails: and(
    'isNotInstalled',
    'appPackage.pricingPageUrl',
    'doesNotHavePricingModel',
  ),

  defaultTab: computed('canEditSettings', 'canShowAppOnboarding', function () {
    if (this.canShowAppOnboarding) {
      return APP_PACKAGE_TABS.onboarding;
    } else if (this.canEditSettings) {
      return APP_PACKAGE_TABS.settings;
    }
  }),

  showSyncErrorsIndicator: computed(
    '_canSeeSyncErrorsIndicator',
    'showHealth',
    'lastSyncErrorUpdatedAt',
    'syncErrorsLastSeenAt',
    function () {
      if (
        !this._canSeeSyncErrorsIndicator ||
        this.showHealth ||
        isEmpty(this.lastSyncErrorUpdatedAt)
      ) {
        return false;
      }

      return (
        isEmpty(this.syncErrorsLastSeenAt) ||
        this.lastSyncErrorUpdatedAt > new Date(parseInt(this.syncErrorsLastSeenAt, 10))
      );
    },
  ),

  _canSeeSyncErrorsIndicator: computed(
    'onPublicAppStore',
    'app.canSeeSyncErrorsNotificationIndicator',
    'canShowHealth',
    'appPackage.implementationIdentifier',
    function () {
      return (
        !this.onPublicAppStore &&
        this.app.canSeeSyncErrorsNotificationIndicator &&
        this.canShowHealth &&
        this.appPackage.implementationIdentifier === 'salesforce-by-intercom'
      );
    },
  ),

  canShowInAutomationsCategory: computed('appPackage.id', function () {
    return SELECTED_AUTOMATIONS_APP_PACKAGE_CODES.includes(this.get('appPackage.id'));
  }),

  updateSelectedTab() {
    let { is_onboarding, tab } = this.appstoreService.getRedirectQueryParams();
    let selectedTab = null;

    if (this.canShowSettings && tab === APP_PACKAGE_TABS.settings) {
      selectedTab = APP_PACKAGE_TABS.settings;
    } else if (this.canShowHealth && tab === APP_PACKAGE_TABS.health) {
      selectedTab = APP_PACKAGE_TABS.health;
    } else if (
      this.canShowAppOnboarding &&
      (is_onboarding || tab === APP_PACKAGE_TABS.onboarding)
    ) {
      selectedTab = APP_PACKAGE_TABS.onboarding;
    } else if (tab === APP_PACKAGE_TABS.description) {
      selectedTab = APP_PACKAGE_TABS.description;
    }

    if (!selectedTab) {
      selectedTab = this.defaultTab;
    }

    this.set('selectedTab', selectedTab);
  },

  createAppPackageSettings(settings) {
    let normalizedSettings = this.store.normalize(this.settingsModelPath, settings).data.attributes;
    return this.store.createFragment(this.settingsModelPath, normalizedSettings);
  },

  sendInstallNotification(installSuccess, errorMessage) {
    if (installSuccess === 'true') {
      let message = this.installConfirmationText;
      this.notificationsService.notifyConfirmation(message);
    } else {
      let message = this.installErrorText;
      if (errorMessage !== undefined && installSuccess === 'false') {
        message = `${message}: ${errorMessage}`;
      }
      this.notificationsService.notifyError(message);
    }
  },

  sendErrorNotification(errorMessage) {
    let message;
    if (errorMessage === 'default') {
      message = this.intl.t('appstore.app-package.error-occurred-try-again');
    } else {
      message = this.intl.t('appstore.app-package.error-message', {
        errorMessage,
      });
    }
    this.notificationsService.notifyError(message);
  },

  install: task(function* (params) {
    this._trackButtonClick('clicked_install');
    this._trackEmailRedirectAction('install');
    try {
      if (this.get('appPackage.isOAuthInstall')) {
        let response = yield this._generateInstallUrl(params);

        this.redirectService.redirect(response.url);
      } else {
        let installedAppPackage = yield this._sendInstallRequest();
        this.store.pushPayload({
          'appstore/app-package': installedAppPackage,
        });
        this.updateSelectedTab();
        this.sendInstallNotification('true');
        this._completeOnboardingStep();
        yield this.fetchSettings.perform();
      }
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  reinstall: task(function* () {
    this._trackEmailRedirectAction('reinstall');
    this._trackButtonClick('clicked_reinstall');
    try {
      let response = yield this._sendReinstallRequest();
      this.redirectService.redirect(response.url);
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  uninstall: task(function* (formatted_feedback, feedback) {
    this._trackButtonClick('uninstalled');
    this._trackEmailRedirectAction('uninstall');
    try {
      let uninstalledAppPackage = yield this._sendUninstallRequest(formatted_feedback, feedback);
      if (this.afterUninstall) {
        yield this.afterUninstall.perform();
      }
      this._updateAppPackageModelsAfterUninstall(uninstalledAppPackage);
      this.updateSelectedTab();
      this.set('showUninstallModal', false);
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.app-package.successfully-uninstalled', {
          appPackageName: this.get('appPackage.name'),
        }),
      );
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  update: task(function* (settingsFragment) {
    this._trackButtonClick('saved_settings');
    try {
      let settings = settingsFragment.serialize();
      let updateResponse = yield this._settingsRequest('POST', { settings });
      this.appPackageSettings.setProperties(updateResponse);
      this._trackUpdatedSettings();
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.app-package.settings-updated'),
      );
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
      this.fetchSettings.perform();
    }
  }).drop(),

  fetchSettings: task(function* () {
    if (!this.shouldFetchSettingsModel) {
      return;
    }
    try {
      let settings = yield this._settingsRequest('GET');
      if (settings) {
        this.set('appPackageSettings', this.createAppPackageSettings(settings));
      }
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).restartable(),

  orchestrateSyncErrorsNotificationIndicator: task(function* () {
    if (this.showHealth || !this._canSeeSyncErrorsIndicator) {
      return;
    }

    let latestSyncError = yield get('/ember/salesforce_integrations/latest_sync_error', {
      app_id: this.appService.app.id,
    });

    this.set('syncErrorsLastSeenAt', localStorage.getItem(this._lastErrorSeenAtKey));
    this.set(
      'lastSyncErrorUpdatedAt',
      latestSyncError?.updated_at && new Date(latestSyncError.updated_at),
    );
  }).restartable(),

  _lastErrorSeenAtKey: computed('app.id', function () {
    return `${SALESFORCE_APP_LAST_ERROR_SEEN_AT_KEY}-${this.app.id}`;
  }),

  generateRedirectUrl: task(function* (redirectUrl, shouldSign) {
    try {
      let response = yield this._generateRedirectUrl(redirectUrl, shouldSign);
      return response.url;
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  _settingsRequest(type = 'GET', requestParams = {}) {
    let defaultParams = {
      admin_id: this.get('appService.app.currentAdmin.id'),
      app_id: this.get('appService.app.id'),
      implementation_identifier: this.get('appPackage.implementationIdentifier'),
    };

    let data = Object.assign({}, defaultParams, requestParams);
    if (type === 'POST') {
      data = JSON.stringify(data);
    }
    return ajax({
      data,
      type,
      noRedirectOn401: true,
      url: '/ember/appstore/settings',
    });
  },

  _generateInstallUrl(params) {
    let additional_params = params || {};
    return this._createRequest('/ember/appstore/redirect_url', 'POST', {
      additional_params,
    });
  },

  _trackEmailRedirectAction(actionType) {
    let { email_request } = this.appstoreService.getRedirectQueryParams();
    if (!email_request) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      object: this.appPackage,
      action: `${email_request}_${actionType}`,
    });
  },

  _sendInstallRequest() {
    return this._createRequest('/ember/appstore/app_packages/install', 'POST');
  },

  _sendReinstallRequest() {
    return this._createRequest('/ember/appstore/app_packages/reinstall', 'POST');
  },

  _sendUninstallRequest(formatted_feedback, feedback) {
    let params = {
      formatted_feedback,
      feedback,
    };
    return this._createRequest('/ember/appstore/app_packages/uninstall', 'POST', params);
  },

  _generateRedirectUrl(redirectUrl, shouldSign) {
    let params = {
      redirect_url: redirectUrl,
      should_sign: shouldSign,
    };
    return this._createRequest('/ember/appstore/redirect_url', 'POST', params);
  },

  _createRequest(url, type = 'GET', requestParams = {}) {
    let defaultParams = {
      admin_id: this.get('appService.app.currentAdmin.id'),
      app_id: this.get('appService.app.id'),
      app_package_code: this.get('appPackage.id'),
    };
    let data = Object.assign({}, defaultParams, requestParams);
    if (type === 'POST') {
      data = JSON.stringify(data);
    }
    return ajax({ data, type, url });
  },

  _updateAppPackageModelsAfterUninstall(uninstalledAppPackage) {
    this.store.pushPayload({
      'appstore/app-package': uninstalledAppPackage,
    });
    let appCard = this.store.peekRecord('appstore/app-card', uninstalledAppPackage.id);
    if (appCard) {
      appCard.set('isInstalled', false);
    }
  },

  async _completeOnboardingStep() {
    if (this.guideLibraryService.canUseGuideLibraryService) {
      if (['WhatsApp', 'Instagram', 'Facebook', 'Twitter'].includes(this.get('appPackage.name'))) {
        await this.guideLibraryService.markStepCompleted(
          'guide_library_foundational_steps_setup_omnichannel_v2',
        );
      } else {
        await this.guideLibraryService.markStepCompleted(
          'guide_library_foundational_steps_integrate_with_other_tools',
        );
      }
    } else {
      await this.onboardingHomeExternalStepService.continueSession();

      if (this.onboardingHomeExternalStepService.isCurrentExternalStep('install_an_app')) {
        this.onboardingHomeExternalStepService.completeExternalStep();
      }
    }
  },

  _trackButtonClick(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: this.appPackage,
      action,
    });
  },

  _trackUpdatedSettings() {
    if (this.get('appPackageSettings.analyticsData')) {
      this.intercomEventService.trackAnalyticsEvent({
        object: this.appPackageSettings,
        action: 'changed',
        place: 'app_package',
      });
    }
  },

  _trackTabSelected(tab) {
    this.intercomEventService.trackAnalyticsEvent({
      object: this.appPackage,
      action: 'tab_selected',
      place: 'app_package',
      selected_tab: tab,
    });
    this._trackEmailRedirectAction(`tab_selected_${tab}`);
  },

  _trackLinkToSettingsClick() {
    this.intercomEventService.trackAnalyticsEvent({
      object: this.appPackage,
      action: 'click',
      place: 'app_package',
      current_tab: 'onboarding',
      selected_tab: 'settings',
    });
  },

  registerHooks: action(function (hooks) {
    this.setProperties(hooks);
  }),

  onTabSelected: action(function (tab) {
    this.set('selectedTab', tab);
    if (typeof this.transitionAppPackageTab !== 'undefined') {
      this.transitionAppPackageTab(tab);
    }
    this._trackTabSelected(tab);
  }),

  redirectToSignIn: action(function () {
    this._trackButtonClick('clicked_sign_in_to_install_button');
    this.appstoreService.redirectToWorkspaceAppstore();
  }),

  onSettingsLinkClicked: action(function () {
    let destination = 'settings';
    this.set('selectedTab', destination);
    this.transitionAppPackageTab(destination);
    this._trackLinkToSettingsClick();
  }),

  onSyncErrorsLoad: action(function () {
    let currentTime = Date.now();

    this.set('syncErrorsLastSeenAt', currentTime);
    localStorage.setItem(this._lastErrorSeenAtKey, currentTime);
  }),

  trackPricingUrlClick: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      object: this.appPackage,
      action: 'pricing_url_click',
    });
  }),
});
