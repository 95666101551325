/* import __COLOCATED_TEMPLATE__ from './about-profile-admin-alias-component.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';

interface Args {
  editableMode: boolean;
  aliasEnabled: boolean;
  editableAliasName: string;
}

export default class AboutProfileAdminAliasComponent extends Component<Args> {
  get containerClasses(): string {
    let classes = ['flex', 'mt-2 pt-0.5'];

    if (this.args.editableMode && this.args.aliasEnabled) {
      classes.push('rounded-xl', 'bg-base-module-subtle', 'p-2', '-mx-2');
    }

    return classes.join(' ');
  }

  get flexClasses(): string {
    return this.args.editableMode ? 'flex-col' : 'flex-row';
  }

  get showAliasLabel(): boolean {
    return !this.args.editableAliasName || !this.args.aliasEnabled || this.args.editableMode;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::AboutProfileAdminAlias': typeof AboutProfileAdminAliasComponent;
    'admin/about-profile-admin-alias': typeof AboutProfileAdminAliasComponent;
  }
}
