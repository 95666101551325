/* import __COLOCATED_TEMPLATE__ from './footer-text-customization-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  BaseConfig,
  BlocksDocument,
  INPUT_RULE_EMOJI,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_ORDERED_NESTED_LIST,
  INPUT_RULE_UNORDERED_NESTED_LIST,
  type InitialSelection,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_CODE_BLOCK,
} from '@intercom/embercom-prosemirror-composer';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';
import type HelpCenterFooterTextContent from 'embercom/models/help-center-footer-text-content';
import { type TextDirection } from '@intercom/embercom-prosemirror-composer/addon/lib/config/composer-config';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { type UploadConfig } from '@intercom/embercom-prosemirror-composer/addon/lib/config/composer-config';
import { inject as service } from '@ember/service';

interface Args {
  showSideDrawer: boolean;
  setShowSideDrawer: (value: boolean) => void;
  helpCenterSite: HelpCenterSite;
}

class FooterComposerConfig extends BaseConfig {
  showAllTextAlignment = true;
  typeaheads = [EMOJI_TYPEAHEAD];
  allowedInline = ['bold', 'italic', 'anchor', 'code', 'inlineImage', 'span'];
  allowEmptyParagraphs = true;
  tables = {
    responsive: true,
    containers: false,
    resizeColumns: true,
  };
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    uploader: EmbercomFileUploader,
  } as UploadConfig;
  enableAutoLink = true;
  updateImages = true;
  resizeImages = true;
  initialSelectionLocation = 'end' as InitialSelection;
  customStylesConfig = {
    allowCustomStyles: true,
    colorPickerComponent: 'composer/help-center-footer-editor/color-picker',
    colorPickerApplyMode: 'selection',
  };

  constructor(app: $TSFixMe, textDirection: TextDirection = 'ltr') {
    super();

    this.textDirection = textDirection;
    this.upload.attrs = { policyUrl: `/apps/${app.id}/uploads` };
    this.experimental.inlineResizeImages = true;

    let tools = [
      { name: 'text-formatter' },
      { name: 'anchor-editor' },
      { name: 'table-editor' },
      { name: 'image-editor', options: { supportAltAttributeEditing: true } },
      { name: 'media-toolbar', options: { showListToolbarIcon: true } },
    ];

    let blocks = [
      'paragraph',
      'image',
      'inlineImage',
      'heading',
      'subheading',
      'horizontalRule',
      'table',
      'orderedNestedList',
      'unorderedNestedList',
      'spacer',
      'codeBlock',
    ];

    this.inputRules = [
      INPUT_RULE_EMOJI,
      INPUT_RULE_ORDERED_NESTED_LIST,
      INPUT_RULE_UNORDERED_NESTED_LIST,
      INPUT_RULE_INLINE_CODE,
      INPUT_RULE_CODE_BLOCK,
    ];

    this.tools = tools;
    this.allowedBlocks = blocks;
  }
}

export default class FooterTextCustomizationSideDrawer extends Component<Args> {
  @service declare appService: $TSFixMe;
  @tracked composer: any;
  @tracked selectedLocale: SupportedLocale;
  @tracked declare footerTextContent: HelpCenterFooterTextContent;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.selectedLocale = this.args.helpCenterSite.defaultLocale;
    this.setFooterTextContent(this.selectedLocale.localeId);
  }

  get composerConfig() {
    return new FooterComposerConfig(this.appService.app);
  }

  get blocksDoc() {
    return new BlocksDocument(this.footerTextContent.jsonBlocks);
  }

  get languageItems() {
    return this.args.helpCenterSite.selectedLocalesOrdered.map((locale: SupportedLocale) => ({
      text: locale.name,
      value: locale.localeId,
    }));
  }

  get showLanguageSelect() {
    return this.args.helpCenterSite.selectedLocalesOrdered.length > 1;
  }

  @action
  onClose() {
    this.args.setShowSideDrawer(false);
  }

  @action
  setSelectedLocale(locale: string) {
    let selectedLocale = this.args.helpCenterSite.selectedLocalesOrdered.find(
      (supportedlocale: SupportedLocale) => supportedlocale.localeId === locale,
    );
    if (selectedLocale) {
      this.selectedLocale = selectedLocale;
      this.setFooterTextContent(selectedLocale.localeId);
    }
  }

  @action
  composerReady(composer: any) {
    this.composer = composer;
  }

  @action
  composerChanged(newBlocksDocument: BlocksDocument) {
    if (this.footerTextContent) {
      this.footerTextContent.jsonBlocks = newBlocksDocument.blocks;
    }
  }

  private setFooterTextContent(locale: string) {
    let footerTextContent = this.args.helpCenterSite.customizedFooterTextContents?.findBy(
      'locale',
      locale,
    );
    if (footerTextContent) {
      this.footerTextContent = footerTextContent;
    } else {
      this.footerTextContent = this.args.helpCenterSite.addCustomizedFooterTextContent(locale);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Footer::FooterTextCustomizationSideDrawer': typeof FooterTextCustomizationSideDrawer;
  }
}
