/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { isPresent } from '@ember/utils';
import type Session from 'embercom/services/session';
import type Configuration from 'embercom/models/side-by-side/handover/configuration';
import type Store from '@ember-data/store';

export default class Main extends Component {
  @service declare session: Session;
  @service declare store: Store;

  @tracked configuration: Configuration | null = null;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadConfiguration).perform();
  }

  get isAuthenticated() {
    if (!this.configuration) {
      return false;
    }

    switch (this.configuration.activeTool) {
      case 'zendesk':
        return isPresent(this.configuration.zendeskConfiguration?.subdomain);
      default:
        return false;
    }
  }

  get isConfigured() {
    if (!this.configuration) {
      return false;
    }

    switch (this.configuration.activeTool) {
      case 'zendesk':
        return isPresent(this.configuration.zendeskConfiguration?.zendeskForm);
      default:
        return false;
    }
  }

  @task({ restartable: true })
  *loadConfiguration() {
    this.configuration = yield this.store.findRecord(
      'side-by-side/handover/configuration',
      this.session.workspace.id,
    );
  }

  @action
  initializeZendeskConfig() {
    if (this.configuration !== null) {
      this.configuration.zendeskConfiguration = this.store.createRecord(
        'side-by-side/handover/zendesk/configuration',
        {},
      );
    }
  }

  @action
  editRouting() {
    if (this.configuration !== null) {
      this.configuration.zendeskConfiguration = null;
    }
  }

  @action
  async turnOnRouting() {
    try {
      if (this.configuration !== null) {
        this.configuration.save();
      }
    } catch (error) {
      console.error(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Appstore::AppPackage::Settings::ZendeskHandover::Main': typeof Main;
    'appstore/app-package/settings/zendesk-handover/main': typeof Main;
  }
}
