/* import __COLOCATED_TEMPLATE__ from './export.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
/* eslint-disable ember/no-classic-components */
import $ from 'jquery';
import { equal, readOnly } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';
import pluralise from 'embercom/lib/inflector';
import { inject as service } from '@ember/service';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import BulkActionModal from 'embercom/controllers/mixins/users-and-companies/bulk-action-modal';
import { action } from '@ember/object';
import Component from '@ember/component';

export default Component.extend(BulkActionModal, {
  tagName: '',
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  init() {
    this._super(...arguments);
    this.reset();
  },
  reset() {
    this.setProperties({
      attributeExportMode: 'all',
      isSaving: false,
      hasErrored: false,
    });
  },
  modalAction: 'export',
  allAttributesSelected: equal('attributeExportMode', 'all'),
  notificationMessage() {
    let targetCount = this.get('model.hasCount') ? this.get('model.count') : 'the selected';
    let pluralisedTarget = pluralise(this.modalTarget, targetCount, this.modalTargetPlural);
    return `You will be emailed a CSV of ${targetCount} ${pluralisedTarget}. At peak times this can take a few hours`;
  },
  submitUrl: '/ember/companies/bulk_export.json',
  modalTarget: 'company',
  modalTargetPlural: 'companies',
  closeModal: action(function () {
    this.modalService.closeModal();
  }),
  onSubmit: action(function () {
    this.intercomEventService.trackEvent('export-csv', {
      action: 'exported',
      object: 'csv',
      place: 'users_and_companies',
      owner: 'growth',
      type: this.modalTargetPlural,
    });
    let self = this;
    this.set('isSaving', true);
    this.set('hasErrored', false);
    ajax({
      url: self.get('submitUrl'),
      type: 'POST',
      data: JSON.stringify(
        $.extend(this.model.toApi(), {
          app_id: this.get('app.id'),
          all_attributes: this.allAttributesSelected,
        }),
      ),
    }).then(
      function () {
        self.notificationsService.notifyConfirmation(self.notificationMessage());
        self.reset();
        self.closeModal();
      },
      function (jqXHR) {
        self.processBulkActionFailure(jqXHR, 'isSaving');
      },
    );
  }),
});
