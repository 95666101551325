/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { post } from 'embercom/lib/ajax';

//Magic number but its the correct size for the modal without extending it.
const PAGE_SIZE = 7;

const DIRECTION_LABELS = {
  intercom_to_crm: 'Intercom to Salesforce',
  crm_to_intercom: 'Salesforce to Intercom',
};
export default class ErrorLogs extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;

  @tracked tableData = [];
  @tracked syncHealthData;
  @tracked selectedHealthObject = 'Lead';
  @tracked pageEndIndex = PAGE_SIZE;
  @tracked modalLog = null;
  @tracked showConfigBanner = false;
  @tracked retryLog = null;
  @tracked emptyState = false;
  @tracked canLoadMore = false;
  @tracked canRetryLogs = true;

  constructor() {
    super(...arguments);

    this.loadSyncHealthData.perform();
  }

  @action
  setTableData() {
    this.pageEndIndex += PAGE_SIZE;
    this.tableData = this.syncHealthData.logs.slice(0, this.pageEndIndex);
    this.setCanLoadMore();
    this.trackAnalyticsEvent('clicked', 'load_more', {
      table_end_index: this.pageEndIndex,
    });
  }

  get showDetailModal() {
    this.trackAnalyticsEvent('clicked', 'detail_button', {
      detail_log_source: this.selectedHealthObject,
    });
    return isPresent(this.modalLog);
  }

  get showRetryModal() {
    return isPresent(this.retryLog);
  }

  get columns() {
    let columns = [
      {
        label: 'Date and time',
        valuePath: 'updatedAt',
        width: '175px',
        maxWidth: '30%',
      },
      {
        label: 'ID of the record',
        valuePath: 'sourceObjectId',
        width: '140px',
        maxWidth: '25%',
      },
    ];

    if (this.appService.app.canUseHistoricalBulkSync) {
      columns.push({
        label: 'Type',
        valuePath: 'type',
        width: '140px',
        maxWidth: '25%',
        type: 'stamp',
        tooltip: 'The type of sync that originated the error.',
      });
    } else {
      columns.push({
        label: 'Status',
        valuePath: 'status',
        width: '140px',
        maxWidth: '25%',
        type: 'stamp',
      });
    }

    columns.push({
      label: 'Description',
      valuePath: 'context',
      maxWidth: '45%',
    });

    return columns;
  }

  setCanLoadMore() {
    this.canLoadMore = this.syncHealthData.logs.length > this.pageEndIndex;
  }

  @action
  setRetryLog(log) {
    this.retryLog = log;
  }

  @action
  clearRetryLog() {
    this.retryLog = null;
  }

  @action
  clearDetailLog() {
    this.modalLog = null;
    this.trackAnalyticsEvent('clicked', 'hide_detail');
  }

  @action
  retryDetail() {
    this.retryLog = this.modalLog;
  }

  @action
  setDetailModal(log) {
    this.modalLog = log;
  }

  @action
  showEmptyState() {
    this.emptyState = isEmpty(this.tableData);
    if (this.emptyState) {
      this.trackAnalyticsEvent('show_logs', 'empty_table');
    } else {
      this.trackAnalyticsEvent('show_logs', 'table_with_entries');
    }
  }

  @action
  retryErrorLog() {
    this.syncErrorLog(this.retryLog);
  }

  @task({ restartable: true })
  *loadSyncHealthData() {
    this.emptyState = false;
    this.tableData = [];

    let objects = null;
    switch (this.selectedHealthObject) {
      case 'Account':
        objects = 'companies_and_accounts';
        break;
      case 'Lead':
        objects = 'leads_and_contacts';
        break;
      case 'Case':
        objects = 'conversation_and_cases';
        break;
      default:
        console.error('Error while fetching the sync health logs. Unhandled health object type.');
    }

    try {
      this.syncHealthData = yield this.store.queryRecord(
        'appstore/app-package/salesforce/sync-health-data',
        { section_id: 'sync_health', source_object_type: objects },
      );
      this.parseHealthData();
      this.tableData = this.syncHealthData.logs.slice(0, PAGE_SIZE);
      this.shouldShowHelpBanner();
      this.canRetryLogs = !this.syncHealthData.throttled;
      this.pageEndIndex = PAGE_SIZE;
      this.showEmptyState();
      this.setCanLoadMore();
      this.args.onSyncErrorsLoad();
    } catch (e) {
      this.notificationsService.notifyError(
        'We encountered an error, please refresh the page and try again',
      );
    }
  }

  async syncErrorLog(log) {
    try {
      let params = {
        app_id: this.appService.app.id,
        object_type: log.sourceObjectType,
        object_id: log.sourceObjectId,
        direction: log.direction,
      };

      let response = await post('/ember/salesforce_integrations/sync_object', params);
      if (response['success']) {
        this.removeRetriedLog(log);
      } else {
        this.loadSyncHealthData.perform();
        this.notificationsService.notifyError(
          'We were unable to retry the sync, check the log for more information',
        );
      }
      this.clearRetryLog();
    } catch (response) {
      this.notificationsService.notifyError('We were unable to retry this log, please try again.');
      this.clearRetryLog();
    }
  }

  parseHealthData() {
    this.syncHealthData.logs.forEach((log) => {
      log['parsedDirection'] = DIRECTION_LABELS[log.direction];
      let index = log.context.message.indexOf(':');
      log['hasErrorTitle'] = index !== -1;
      log['errorTitle'] =
        log.hasErrorTitle || index >= 1
          ? log.context.message.substring(0, index)
          : 'No Error Title';
    });
  }

  removeRetriedLog(log) {
    this.syncHealthData.logs.removeObject(log);
    this.tableData.removeObject(log);
    this.notificationsService.notifyConfirmation(
      'We successfully retried the sync that caused this error.',
    );
  }

  shouldShowHelpBanner() {
    this.showConfigBanner =
      this.syncHealthData.accountIdentityMappingField !== null &&
      this.syncHealthData.accountIdentityMappingCount === 0 &&
      this.syncHealthData.accountAttributeMappingCount !== 0 &&
      this.syncHealthData.lastAccountTriggerEncounteredAt >
        this.syncHealthData.accountIdentityMappingVersion;
  }

  @action
  trackAnalyticsEvent(_action, _object, metadata) {
    this.intercomEventService.trackAnalyticsEvent({
      action: _action,
      object: _object,
      place: 'sync_health',
      ...metadata,
    });
  }

  @action
  onSelectionChange(selectedValue) {
    this.trackAnalyticsEvent('toggled_switch', 'object_radio_button');
    this.selectedHealthObject = selectedValue;
    this.loadSyncHealthData.perform();
  }
}
