/* import __COLOCATED_TEMPLATE__ from './mappings.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';
import { syncDirection } from 'embercom/models/crm/attribute-mapping-constants';
import { tracked } from '@glimmer/tracking';

export default class AttributeMapping extends Component {
  @service appService;

  @tracked createMode = false;

  constructor() {
    super(...arguments);
    if (this.args.createMode !== undefined) {
      this.createMode = this.args.createMode;
    }
  }

  get columns() {
    if (this.createMode) {
      return [
        {
          label: 'Intercom attribute',
          valuePath: 'intercomAttribute',
          width: '45%',
          icon: 'intercom',
        },
        { label: '', valuePath: 'arrowSeparator', width: '5%' },
        {
          label: 'Salesforce field',
          valuePath: 'salesforceField',
          width: '50%',
          icon: 'salesforce',
        },
      ];
    } else {
      return [
        {
          label: 'Intercom attribute',
          valuePath: 'intercomAttribute',
          width: '30%',
          icon: 'intercom',
        },
        { label: '', valuePath: 'arrowSeparator', width: '5%' },
        {
          label: 'Salesforce field',
          valuePath: 'salesforceField',
          width: '30%',
          icon: 'salesforce',
        },
        { label: 'Direction', valuePath: 'direction', width: '35%' },
      ];
    }
  }

  get tableData() {
    return this.args.attributeMappings
      ?.map((mapping) => this.generateRowData(mapping))
      .sortBy('updatedAt');
  }

  generateRowData(mapping) {
    let intercomAttributePath;
    let salesforceFieldPath;
    let direction;
    let destinationObjectType;

    if (isEmpty(mapping.direction) || mapping.direction === syncDirection.IntercomToCRM) {
      intercomAttributePath = mapping.sourceAttributePath;
      salesforceFieldPath = mapping.destinationAttributePath;
      destinationObjectType = mapping.destinationObjectType;
      direction = syncDirection.IntercomToCRM;
    } else {
      intercomAttributePath = mapping.destinationAttributePath;
      salesforceFieldPath = mapping.sourceAttributePath;
      direction = syncDirection.CrmtoIntercom;
    }

    let intercomAttribute = this.getIntercomAttributeByIndentifier(intercomAttributePath);
    let salesforceField = this.getSalesforceField(salesforceFieldPath);

    return {
      id: mapping._id,
      intercomAttributePath,
      salesforceFieldPath,
      direction,
      destinationObjectType,
      intercomAttributeLabel:
        intercomAttribute?.name || this.getAttributeName(intercomAttributePath),
      salesforceFieldLabel: salesforceField?.label || salesforceFieldPath,
      isIntercomAttributeMissing: mapping.editable && isNone(intercomAttribute),
      isSalesforceFieldMissing: mapping.editable && isNone(salesforceField),
      disableEmptySourceSync: mapping.disableEmptySourceSync,
      disableNonEmptyDestinationSync: mapping.disableNonEmptyDestinationSync,
      editable: mapping.editable,
      createOnly: this.getIntercomCreateOnly(intercomAttribute),
      updatedAt: mapping.updatedAt,
    };
  }

  findExistingMapping(row) {
    let sourceAttributePath =
      row.direction === syncDirection.IntercomToCRM
        ? row.intercomAttributePath
        : row.salesforceFieldPath;
    return this.args.attributeMappings.findBy('sourceAttributePath', sourceAttributePath);
  }

  getIntercomAttributeByIndentifier(identifier) {
    return this.args.intercomAttributes.findBy('identifier', identifier);
  }

  getIntercomCreateOnly(attribute) {
    return this.args.intercomAttributes.findBy('identifier', attribute)?.crm_create_only;
  }

  getSalesforceField(field) {
    return this.args.salesforceFields.findBy('path', field);
  }

  getAttributeName(attribute) {
    return attribute.split('.').pop();
  }

  @action
  removeAttributeMappingRow(row) {
    let removedMapping = this.findExistingMapping(row);
    this.args.removeAttributeMapping(removedMapping);
  }
}
