/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import { readOnly, empty, alias, gt } from '@ember/object/computed';
import RSVP from 'rsvp';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  store: service(),
  intercomConfirmService: service(),
  redirectService: service(),
  appstoreService: service(),
  tagName: '',

  init() {
    this._super(...arguments);
    this.setupSettingsData.perform().then(() => {
      let { redirect } = this.appstoreService.getRedirectQueryParams();
      if (redirect && !this.isGithubTokenAvailable) {
        this.connectToGitHub.perform();
      }
    });
    this.registerHooks({ afterUninstall: this.cleanupPostUninstall });
  },

  githubIntegration: null,
  githubWebhooks: null,
  githubAuthRedirectUrl: readOnly('githubIntegration.redirectUrl'),
  localDevTeammateAppUrl: 'http://app.intercom.test',
  isGithubTokenAvailable: empty('githubAuthRedirectUrl'),
  githubRepositories: alias('githubIntegration.repositories'),
  defaultGithubWebhookConfigId: alias('githubIntegration.defaultGithubWebhookConfigId'),
  defaultGithubWebhook: findByProperty('githubWebhooks', 'id', 'defaultGithubWebhookConfigId'),
  repositoriesNextPage: alias('githubIntegration.nextPage'),

  webhookListItems: computed(
    'githubWebhooks.@each.fullName',
    'defaultGithubWebhookConfigId',
    function () {
      return this.githubWebhooks
        .filter((webhook) => webhook.get('fullName'))
        .map((webhook) => ({
          value: webhook.get('id'),
          text: webhook.get('fullName'),
          isSelected: this.defaultGithubWebhookConfigId === webhook.get('id'),
        }));
    },
  ),
  hasRegisteredMoreThanOneWebhook: gt('webhookListItems.length', 1),

  setupSettingsData: task(function* () {
    let data = yield this.fetchGithubModels();

    this.setProperties({
      githubIntegration: data.githubIntegration,
      githubWebhooks: data.githubWebhooks,
    });
  }).drop(),

  fetchGithubModels() {
    return RSVP.hash({
      githubWebhooks: this.store.findAll('github-webhook'),
      githubIntegration: this.store.queryRecord('github-integration', {
        is_app_store: true,
      }),
    });
  },

  loadMoreRepositoriesTask: task(function* () {
    let nextPage = this.repositoriesNextPage;
    let githubIntegration = yield this.store.queryRecord('github-integration', {
      page: nextPage,
    });
    this.githubRepositories.pushObjects(githubIntegration.get('repositories').toArray());
    this.set('repositoriesNextPage', githubIntegration.get('nextPage'));
  }).drop(),

  addWebhook: task(function* (repository) {
    let webhook = this.store.createRecord('github-webhook', {
      repository,
    });

    try {
      yield webhook.save();

      // Refresh github-integration because default GitHub webhook doesn't refresh on it's own
      yield this.store.queryRecord('github-integration', {});
      this.notificationsService.notifyConfirmation('Repo added');
      this.intercomEventService.trackAnalyticsEvent({
        action: 'added',
        object: `github-repo`,
      });
    } catch (e) {
      this.notificationsService.notifyError('An error occurred while adding the Github repo');
      webhook.unloadRecord();
    }
  }).drop(),

  removeWebhook: task(function* (webhook) {
    let options = {
      title: 'Remove repo',
      body: 'Are you sure you want to remove this repo?',
      confirmButtonText: 'Remove',
    };

    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (!confirmed) {
      return;
    }

    try {
      yield webhook.content.destroyRecord();
      yield this.store.queryRecord('github-integration', {});
      this.intercomEventService.trackAnalyticsEvent({
        action: 'removed',
        object: `github-repo`,
      });
    } catch (error) {
      this.notificationsService.notifyError(`Could not remove repo: ${webhook.get('fullName')}`);
    }
  }),

  selectDefaultRepo: task(function* (githubWebhookConfigId) {
    // Refresh github-integration because default GitHub webhook doesn't refresh on it's own
    let githubIntegration = yield this.store.queryRecord('github-integration', {});
    githubIntegration.set('defaultGithubWebhookConfigId', githubWebhookConfigId);
    githubIntegration.save();

    let chosenRepoName = this.get('defaultGithubWebhook.fullName');
    this.notificationsService.notifyConfirmation(`Changed the main repo to ${chosenRepoName}`);
  }),

  connectToGitHub: task(function* () {
    let currentTeammateAppUrl = `${window.location.protocol}//${window.location.hostname}`;
    try {
      let redirectUrl = this.githubAuthRedirectUrl;
      if (
        currentTeammateAppUrl !== TEAMMATE_APP_URL &&
        currentTeammateAppUrl !== this.localDevTeammateAppUrl
      ) {
        redirectUrl = `${window.location.href.replace(
          currentTeammateAppUrl,
          TEAMMATE_APP_URL,
        )}&redirect=true`;
      }
      redirectUrl = yield this.generateRedirectUrl.perform(redirectUrl, false);
      this.redirectService.redirect(redirectUrl);
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError('Failed to connect to GitHub');
    }
  }).drop(),

  cleanupPostUninstall: task(function* () {
    yield this.store.unloadAll('github-webhook');
    yield this.githubIntegration.destroyRecord();
  }),
});
