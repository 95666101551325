/* import __COLOCATED_TEMPLATE__ from './question-box.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type { PlaygroundTestQuestion } from 'embercom/lib/fin-playground';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface QuestionBoxArgs {
  question: PlaygroundTestQuestion;
  isSelected: boolean;
  onSelect: (questionText: string) => void;
  onRemove: (question_id?: number) => void;
}

type QuestionBoxSignature = {
  Args: QuestionBoxArgs;
  Element: HTMLLIElement;
};

export default class AiAgentPlaygroundQuestionBox extends Component<QuestionBoxSignature> {
  @service declare intl: IntlService;

  @tracked isHovered = false;

  get statusToDisplay() {
    if (this.args.question.customerAnswerRating) {
      return this.args.question.customerAnswerRating;
    } else if (!this.args.question.isNotDirectAnswer) {
      return 'positive';
    } else if (this.args.question.status === 'pending') {
      return 'pending';
    } else {
      return 'needs-rating';
    }
  }

  get statusStyling() {
    switch (this.statusToDisplay) {
      case 'positive':
        return 'bg-success-container';
      case 'negative':
        return 'bg-accent-container';
      default:
        return 'bg-neutral-container-emphasis';
    }
  }

  get answerRatingText() {
    return this.intl.t(`ai-agent.playground.answer-rating.${this.statusToDisplay}`);
  }

  get questionOptionsList() {
    return [
      {
        text: this.intl.t('ai-agent.playground.delete-question'),
        icon: 'trash',
        onSelectItem: this.deleteQuestion,
        isDisabled: false,
      },
    ];
  }

  @action
  deleteQuestion() {
    this.args.onRemove(this.args.question.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionBox': typeof AiAgentPlaygroundQuestionBox;
  }
}
