/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  init() {
    this._super(...arguments);
    this.registerHooks(this.getProperties('afterUninstall'));
  },
  dropdownItems: [
    {
      text: '100',
      value: 100,
    },
    {
      text: '200',
      value: 200,
    },
    {
      text: '300',
      value: 300,
    },
    {
      text: '400',
      value: 400,
    },
    {
      text: '500',
      value: 500,
    },
  ],
  afterUninstall: task(function* () {
    yield console.info('afterUninstall hook fired!');
  }).drop(),
});
