/* import __COLOCATED_TEMPLATE__ from './admin-email-alias-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  isOpen: boolean;
  onClose: () => void;
}

export default class AdminEmailAliasSideDrawer extends Component<Args> {
  @service appService: any;
  @service intercomEventService: any;

  @tracked showAddCustomSenderModal = false;
  @tracked entities = this._getEntities();
  @tracked isAdminAliasEmailSideDrawer = false;

  get app() {
    return this.appService.app;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  _getEntities() {
    let senderEmails = {
      items: [
        {
          text: this.app.currentAdmin.name,
          value: this.app.currentAdmin.email,
        },
      ],
    };

    return [senderEmails];
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_admin_about_profile',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::AdminEmailAliasSideDrawer': typeof AdminEmailAliasSideDrawer;
    'admin/admin-email-alias-side-drawer': typeof AdminEmailAliasSideDrawer;
  }
}
