/* import __COLOCATED_TEMPLATE__ from './tagging.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Tagging extends Component {
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  @service modalService;

  @action
  closeModal() {
    this.modalService.closeModal();
  }
}
