/* import __COLOCATED_TEMPLATE__ from './upload-questions.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { ParseResult } from 'papaparse';
import csvParser from 'papaparse';
import { Promise as EmberPromise } from 'rsvp';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  onModalClose: () => void;
  maximumLinesToParse: number;
  saveQuestions: (questions: string[]) => void;
}

interface Signature {
  Args: Args;
}

export default class AiAgentUploadQuestions extends Component<Signature> {
  @service declare router: RouterService;
  @service declare intl: IntlService;

  @tracked file = null;
  @tracked fileIsValid = false;
  @tracked fileFailedToUpload = false;
  @tracked parsingErrorMessage = '';
  @tracked hasHeaderRow = false;

  async _parseCsvFile(file: any): Promise<ParseResult<any>> {
    // the CSV parser uses callbacks, so this is just a shim so we can use it as a Promise
    return new EmberPromise((resolve, reject) => {
      let complete = (results: any) => {
        if (results.errors.length) {
          // An UndetectableDelimiter happens with single column CSV files (because no line contains a ,)
          if (results.errors.any((error: any) => error.code !== 'UndetectableDelimiter')) {
            reject(results);
          }
        }
        resolve(results);
      };
      csvParser.parse(file, { complete, preview: this.args.maximumLinesToParse, error: reject });
    });
  }

  @action
  async parseCsvFile() {
    try {
      this.parsingErrorMessage = '';
      let results = await this._parseCsvFile(this.file);

      if (results.data.length === 0) {
        throw new Error(
          this.intl.t('ai-agent.playground.upload-questions-modal.fallback-processing-error'),
        );
      }

      // For the Alpha release, we only support a single column of questions
      let questions = (results as { data: string[] }).data.map((row: any) => row[0]);
      this.args.saveQuestions(questions);

      this.args.onModalClose();
    } catch (error) {
      this.parsingErrorMessage =
        error.message ||
        this.intl.t('ai-agent.playground.upload-questions-modal.fallback-processing-error');
      this.fileFailedToUpload = true;
      this.fileIsValid = false;
    }
  }

  @action
  setFile(file: any) {
    this.file = file;
    this.fileIsValid = file !== null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::UploadQuestions': typeof AiAgentUploadQuestions;
  }
}
