/* import __COLOCATED_TEMPLATE__ from './guidance.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import type IntlService from 'embercom/services/intl';
import { put } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { State } from 'embercom/models/data/matching-system/matching-constants';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';

export const PREVIEW_REFRESH_INTERVAL = 3000;
const SURVEY_ID = 44017774;

interface Args {}
export default class AiAgentGuidance extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;

  MAX_GUIDELINE_COUNT = 100;

  @tracked isProcessing = false;
  @tracked typingTimeout: ReturnType<typeof setTimeout> | null = null;

  get guidelines(): Guideline[] {
    return this.store.peekAll('ai-agent/guidelines').toArray();
  }

  get globalStateStamp(): string {
    return State[this.globalState];
  }

  get guidelineCount(): number {
    return this.guidelines.filter((guideline) => !guideline.isDeleted).length;
  }

  get cannotAddNewGuidelines(): boolean {
    return this.guidelineCount >= this.MAX_GUIDELINE_COUNT;
  }

  get hasInvalidGuidelines(): boolean {
    return this.guidelines.some((guideline) => guideline.validations.isInvalid);
  }

  get validationErrors(): string[] {
    return this.guidelines
      .filter((guideline) => guideline.validations.isInvalid)
      .map((guideline) => guideline.validations.messages)
      .flat()
      .uniq();
  }

  get hasUnsavedChanges(): boolean {
    return this.guidelines.some((guideline) => guideline.hasDirtyAttributes || guideline.isDeleted);
  }

  get hideActionButtons(): boolean {
    return this.guidelines.length === 0 && this.globalState === states.draft;
  }

  get globalState(): number {
    return this.guidelines[0]?.state || states.draft;
  }

  get hasEnabledGuidelines(): boolean {
    return this.guidelines[0]?.state === states.live;
  }

  get disableSaveButton(): boolean {
    return this.hasInvalidGuidelines || !this.hasUnsavedChanges;
  }

  get labelForSetLiveButton(): string {
    if (this.hasUnsavedChanges) {
      return this.intl.t('ai-agent.guidance.save-and-set-live');
    }
    return this.intl.t('ai-agent.guidance.set-all-live');
  }

  get disableSetLiveButton(): boolean {
    // Save and set live
    if (this.hasEnabledGuidelines) {
      return this.disableSaveButton;
    }
    // Set all live
    return (
      this.hasInvalidGuidelines ||
      this.guidelines.filter((guideline) => !guideline.isDeleted).length === 0
    );
  }

  get categories(): Array<{
    id: string;
    title: string;
    description: string;
    icon: InterfaceIconName;
  }> {
    return [
      {
        id: 'tone',
        title: this.intl.t('ai-agent.guidance.guidelines.categories.tone.title'),
        description: this.intl.t('ai-agent.guidance.guidelines.categories.tone.description'),
        icon: 'messenger',
      },
      {
        id: 'clarification',
        title: this.intl.t('ai-agent.guidance.guidelines.categories.clarification.title'),
        description: this.intl.t(
          'ai-agent.guidance.guidelines.categories.clarification.description',
        ),
        icon: 'help-space',
      },
      {
        id: 'routing',
        title: this.intl.t('ai-agent.guidance.guidelines.categories.routing.title'),
        description: this.intl.t('ai-agent.guidance.guidelines.categories.routing.description'),
        icon: 'new-direct-message',
      },
      {
        id: 'other',
        title: this.intl.t('ai-agent.guidance.guidelines.categories.other.title'),
        description: this.intl.t('ai-agent.guidance.guidelines.categories.other.description'),
        icon: 'list',
      },
    ];
  }

  get hasContentReadyForFin() {
    return this.aiAgentSetupService.hasContentReadyForFin;
  }

  get isStandaloneApp() {
    return this.appService.app.canUseFinStandalone;
  }

  async _saveChangedGuidelines(): Promise<void> {
    // .save() by itself is sending requests for all guidelines
    let unsavedGuidelines = this.guidelines.filter((guideline) => guideline.hasDirtyAttributes);

    for (let guideline of unsavedGuidelines) {
      // to ensure new guidelines are created in order
      await guideline.save();
    }
  }

  @dropTask
  *save(): TaskGenerator<void> {
    this.isProcessing = true;
    try {
      yield this._saveChangedGuidelines();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    }
    this.isProcessing = false;
  }

  @action
  cancel(): void {
    for (let guideline of this.guidelines) {
      guideline.rollbackAttributes();
    }
  }

  @dropTask
  *enableAll(): TaskGenerator<void> {
    this.isProcessing = true;
    try {
      yield put(`/ember/ai_agent/guidelines/enable_all`, {
        app_id: this.appService.app.id,
      });

      yield this.store.findAll('ai-agent/guidelines');

      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    }
    this.isProcessing = false;
  }

  @dropTask
  *disableAll(): TaskGenerator<void> {
    this.isProcessing = true;
    try {
      yield put(`/ember/ai_agent/guidelines/disable_all`, {
        app_id: this.appService.app.id,
      });

      yield this.store.findAll('ai-agent/guidelines');

      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    }
    this.isProcessing = false;
  }

  @dropTask
  *saveAndEnableAll(): TaskGenerator<void> {
    this.isProcessing = true;
    try {
      yield this._saveChangedGuidelines();
      yield put(`/ember/ai_agent/guidelines/enable_all`, {
        app_id: this.appService.app.id,
      });

      yield this.store.findAll('ai-agent/guidelines');

      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    }
    this.isProcessing = false;
  }

  get userIsTyping(): boolean {
    return !!this.typingTimeout;
  }

  willDestroy() {
    // We need to clear the timeout in case the user navigates away from the page while typing
    // otherwise the message will be sent to the iframe even after the page has been unloaded
    super.willDestroy();
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
  }

  @action
  handleUserIsTyping(_: KeyboardEvent) {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    this.typingTimeout = setTimeout(() => {
      this.sendMessageToMessengerIFrame('preview-guidelines-updated');
      this.typingTimeout = null;
    }, PREVIEW_REFRESH_INTERVAL);
  }

  @action
  startSurvey() {
    startSurvey(SURVEY_ID);
  }

  @action
  sendMessageToMessengerIFrame(messageType: string) {
    let message: { type: string; previewGuidelines?: string[] } = {
      type: messageType,
      previewGuidelines: this.guidelines
        .filter((guideline) => !guideline.isDeleted)
        .map((guideline) => guideline.prompt),
    };

    let iframe = window.document.querySelector(
      '#hosted-messenger-fin-demo-preview',
    ) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(message));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance': typeof AiAgentGuidance;
  }
}
