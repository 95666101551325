/* import __COLOCATED_TEMPLATE__ from './accordion.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';
import { isPresent } from '@ember/utils';

export default class Accordion extends Component {
  @service appService;
  @service intercomEventService;

  settingsAreDirty = false;
  @tracked openSectionId = null;
  @tracked showConfirmModal = false;
  @tracked nextSectionId = null;

  @action
  setSettingsAreDirty(value) {
    this.settingsAreDirty = value;
  }

  @action
  onOpenSectionChange(sectionId) {
    if (
      (['sync-people-data', 'sync-account-data'].includes(this.openSectionId) &&
        this.args.syncState?.hasDirtyAttributes) ||
      this.settingsAreDirty === true
    ) {
      this.showConfirmModal = true;
      this.nextSectionId = sectionId;
      return;
    }

    if (isPresent(sectionId)) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'accordion',
        place: 'salesforce_settings',
        section: underscore(sectionId),
      });
    }

    this.openSectionId = sectionId;
  }

  @action
  clearAndNavigate() {
    this.args.syncState.rollbackAttributes();
    this.showConfirmModal = false;
    this.settingsAreDirty = false;
    this.openSectionId = this.nextSectionId;
    this.nextSectionId = null;
  }
}
