/* import __COLOCATED_TEMPLATE__ from './editable-name.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default Component.extend({
  notificationsService: service(),
  updateName: task(function* () {
    try {
      yield ajax({
        url: `/ember/company_name/${this.get('company.id')}`,
        data: JSON.stringify({
          new_name: this.newName,
          admin_id: this.get('app.currentAdmin.id'),
          app_id: this.get('app.id'),
        }),
        type: 'PUT',
      });
      this.notificationsService.notifyConfirmation('Company name updated successfully.');
    } catch (error) {
      this.notificationsService.notifyError(error.jqXHR.responseJSON.errors[0].message);
    }
  }).drop(),

  saveChanges: action(function (newValue) {
    if (newValue !== this.get('company.name')) {
      this.set('newName', newValue);
      this.updateName.perform();
    }
  }),
});
