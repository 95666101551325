/* import __COLOCATED_TEMPLATE__ from './attribute-mapping.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { typeMapping, syncDirection } from 'embercom/models/crm/attribute-mapping-constants';

export default class AttributeMapping extends Component {
  @service appService;

  modalTitle = this.isEmptyForm
    ? `Map new attribute for ${this.selectedSalesforceObjectType?.toLowerCase()}s`
    : `Edit attribute mapping for ${this.selectedSalesforceObjectType?.toLowerCase()}s`;
  submitLabel = this.originalMapping ? 'Save' : 'Add';
  integrationCode = 'salesforce-by-intercom';
  syncDirections = [
    { text: 'Intercom to Salesforce', value: syncDirection.IntercomToCRM },
    { text: 'Salesforce to Intercom', value: syncDirection.CrmtoIntercom },
  ];
  originalSourceAttr =
    this.args.selectedSyncDirection === syncDirection.IntercomToCRM
      ? this.args.selectedIntercomAttr
      : this.args.selectedSalesforceAttr;

  @tracked showAttributeMappingModal;
  @tracked isSalesforceDisabled = true;
  @tracked selectedIntercomAttr = this.args.selectedIntercomAttr;
  @tracked selectedSalesforceAttr = this.args.selectedSalesforceAttr;
  @tracked selectedSyncDirection = this.args.selectedSyncDirection;
  @tracked selectedSalesforceObjectType = this.args.selectedSalesforceObjectType;
  @tracked selectedDisableEmptySourceSync = this.args.selectedDisableEmptySourceSync;
  @tracked selectedDisableNonEmptyDestinationSync =
    this.args.selectedDisableNonEmptyDestinationSync;

  @tracked createMode = false;

  constructor() {
    super(...arguments);
    if (this.args.createMode !== undefined) {
      this.createMode = this.args.createMode;
    }
  }

  get selectedSourceAttributePath() {
    return this.isIntercomToCrmDirection ? this.selectedIntercomAttr : this.selectedSalesforceAttr;
  }

  get selectedDestinationAttributePath() {
    return this.isIntercomToCrmDirection ? this.selectedSalesforceAttr : this.selectedIntercomAttr;
  }

  get selectedSourceObjectType() {
    return this.isIntercomToCrmDirection
      ? this.args.intercomObjectType
      : this.selectedSalesforceObjectType;
  }

  get selectedDestinationObjectType() {
    return this.isIntercomToCrmDirection
      ? this.selectedSalesforceObjectType
      : this.args.intercomObjectType;
  }

  get isEmptyForm() {
    return isEmpty(
      this.selectedIntercomAttr && this.selectedSalesforceAttr && this.selectedSyncDirection,
    );
  }

  get submitIsDisabled() {
    let isUntouchedForm =
      this.originalMapping &&
      this.originalMapping.sourceAttributePath === this.selectedSourceAttributePath &&
      this.originalMapping.destinationAttributePath === this.selectedDestinationAttributePath &&
      this.originalMapping.direction === this.selectedSyncDirection &&
      this.originalMapping.disableEmptySourceSync === this.selectedDisableEmptySourceSync &&
      this.originalMapping.disableNonEmptyDestinationSync ===
        this.selectedDisableNonEmptyDestinationSync;

    return this.isEmptyForm || isUntouchedForm || !isEmpty(this.error);
  }

  get selectedDirection() {
    return this.syncDirections.find(
      (syncDirection) => syncDirection.value === this.selectedSyncDirection,
    );
  }

  get intercomAttribute() {
    return this.args.intercomAttributes.findBy('identifier', this.selectedIntercomAttr);
  }

  get salesforceField() {
    return this.args.salesforceFields.findBy('path', this.selectedSalesforceAttr);
  }

  get isIntercomToCrmDirection() {
    return this.selectedSyncDirection === syncDirection.IntercomToCRM;
  }

  get intercomTypeMapping() {
    return typeMapping[this.intercomAttribute?.crmType];
  }

  get isQualificationAttribute() {
    return this.appService.app.qualification_attributes.content
      ?.map((a) => a.identifier)
      .includes(this.intercomAttribute?.identifier);
  }

  get unsupportedMapping() {
    return (
      !isEmpty(this.intercomTypeMapping) &&
      !isEmpty(this.salesforceField?.type) &&
      !isEmpty(this.selectedSyncDirection) &&
      (this.unsupportedConversionFromCRM || this.unsupportedConversionToCRM)
    );
  }

  get unsupportedConversionFromCRM() {
    return (
      !this.isIntercomToCrmDirection &&
      !this.intercomTypeMapping?.from.includes(this.salesforceField?.type)
    );
  }

  get unsupportedConversionToCRM() {
    return (
      this.isIntercomToCrmDirection &&
      !this.intercomTypeMapping?.to.includes(this.salesforceField?.type)
    );
  }

  get originalMapping() {
    if (this.args.selectedMappingId) {
      return this.args.mappings.findBy('_id', this.args.selectedMappingId);
    }
    return this.args.mappings.findBy('sourceAttributePath', this.originalSourceAttr);
  }

  get isCustomAttribute() {
    return this.intercomAttribute?.identifier.includes('custom_attribute');
  }

  get error() {
    let error = '';

    if (this.unsupportedMapping) {
      error = 'type_mapping_error';
    }

    if (
      this.salesforceField &&
      !this.salesforceField?.updateable &&
      this.isIntercomToCrmDirection
    ) {
      error = 'salesforce_not_writable_error';
    }

    if (
      this.intercomAttribute &&
      !this.intercomAttribute?.crm_writable &&
      this.selectedSyncDirection === syncDirection.CrmtoIntercom
    ) {
      error = 'intercom_not_writable_error';
    }

    if (
      this.intercomAttribute &&
      !this.isQualificationAttribute &&
      !this.intercomAttribute?.crm_readable &&
      this.isIntercomToCrmDirection &&
      !this.isCustomAttribute
    ) {
      error = 'intercom_not_readable_error';
    }

    if (this.selectedIntercomAttr && !this.intercomAttribute) {
      error = 'intercom_archived';
    }

    if (this.selectedSalesforceAttr && !this.salesforceField) {
      error = 'salesforce_archived';
    }

    return error;
  }

  @action
  createOrUpdateMapping() {
    let existingMapping = this.originalMapping;

    if (existingMapping) {
      existingMapping.set('sourceAttributePath', this.selectedSourceAttributePath);
      existingMapping.set('destinationAttributePath', this.selectedDestinationAttributePath);
      existingMapping.set('sourceObjectType', this.selectedSourceObjectType);
      existingMapping.set('destinationObjectType', this.selectedDestinationObjectType);
      existingMapping.set('direction', this.selectedSyncDirection);
      existingMapping.set('updatedAt', new Date());
      existingMapping.set('disableEmptySourceSync', this.selectedDisableEmptySourceSync);
      existingMapping.set(
        'disableNonEmptyDestinationSync',
        this.selectedDisableNonEmptyDestinationSync,
      );
    } else {
      this.args.syncState.attributeMappings.createFragment({
        sourceAttributePath: this.selectedSourceAttributePath,
        destinationAttributePath: this.selectedDestinationAttributePath,
        sourceObjectType: this.selectedSourceObjectType,
        destinationObjectType: this.selectedDestinationObjectType,
        appIdCode: this.appService.app.id,
        editable: true,
        direction: this.selectedSyncDirection,
        integrationCode: this.integrationCode,
        sourceType: this.intercomAttribute.type,
        updatedAt: new Date(),
        disableEmptySourceSync: this.selectedDisableEmptySourceSync,
        disableNonEmptyDestinationSync: this.selectedDisableNonEmptyDestinationSync,
      });
    }
    this.closeModal();
  }

  @action
  closeModal() {
    this.args.closeModal();
  }
}
