/* import __COLOCATED_TEMPLATE__ from './multi-install-type-button.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';

export default Component.extend(ComponentDropdownToggler, {
  tagName: 'span',
  alignRight: true,
  classNameBindings: ['alignRight:u__right:u__left'],
  classNames: [
    'integrations-hub__connect-action-dropdown-button',
    'u__relative',
    'flex-auto',
    'flex',
    'flex-row',
  ],

  label: 'Integrate',
  options: null,
  handleButtonClick: null,
  handleOption: null,

  actions: {
    toggleDropdown() {
      if (this.isOpen) {
        this.send('close');
      } else {
        this.send('open');
      }
    },
  },
});
