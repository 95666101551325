/* import __COLOCATED_TEMPLATE__ from './map-account-data.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { syncDirection } from 'embercom/models/crm/attribute-mapping-constants';
import { get } from 'embercom/lib/ajax';

export default class MapAccountData extends Component {
  @service store;
  @service appService;
  @service attributeService;
  @service notificationsService;
  @service intercomEventService;

  @tracked accountSyncData = {};
  @tracked suggestedFields = {};
  @tracked showAttributeMappingModal = false;
  @tracked selectedSalesforceObjectType = 'Account';
  @tracked selectedMappingId;
  @tracked selectedIntercomAttr;
  @tracked selectedSalesforceAttr;
  @tracked selectedSyncDirection;
  @tracked selectedDisableEmptySourceSync = true;
  @tracked selectedDisableNonEmptyDestinationSync = false;
  @tracked hideSyncWarningBanner = false;

  constructor() {
    super(...arguments);

    this.fetchAccountSyncData.perform();
    this.fetchSuggestedFields.perform();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.accountSyncData.rollbackAttributes();
  }

  @task({ restartable: true })
  *fetchAccountSyncData() {
    this.accountSyncData = yield this.store.queryRecord(
      'appstore/app-package/salesforce/account-sync-data',
      { section_id: 'account_sync' },
    );
  }

  @task({ restartable: true })
  *fetchSuggestedFields() {
    this.suggestedFields = yield get('/ember/salesforce_integrations/account_sync_data', {
      app_id: this.appService.app.id,
    });
  }

  get settingsAreDirty() {
    return this.accountSyncData.hasDirtyAttributes;
  }

  get isSaveButtonDisabled() {
    let result =
      !this.settingsAreDirty ||
      isEmpty(this.accountSyncData.settings.salesforceAccountIdentityMappingField);

    this.args.setSettingsAreDirty(!result);

    return result;
  }

  get attributeMappings() {
    return this.accountAttributeMappings;
  }

  get accountAttributeMappings() {
    return this.accountSyncData.attributeMappings.filter((attributeMapping) =>
      [attributeMapping.sourceObjectType, attributeMapping.destinationObjectType].includes(
        this.selectedSalesforceObjectType,
      ),
    );
  }

  get salesforceFields() {
    return this.accountSyncData.salesforceAccountFields;
  }

  get salesforceDropdownFields() {
    let salesforceDropdownFields = this.salesforceFields.map((field) =>
      field.asCrmAttributeMappingOption(
        this.accountSyncData.settings.salesforceAccountIdentityMappingField,
        this.attributeMappings,
        'company',
        this.selectedSalesforceObjectType,
      ),
    );

    let fields = this.salesforceFields.map((field) => field.path);

    if (this.selectedSalesforceAttr && !fields.includes(this.selectedSalesforceAttr)) {
      salesforceDropdownFields.push({
        text: this.selectedSalesforceAttr,
        value: this.selectedSalesforceAttr,
        count: `(Unavailable)`,
        tooltipText: 'This field is no longer available',
        isDisabled: true,
      });
    }

    return salesforceDropdownFields;
  }

  get selectedAccountMappingField() {
    return this.accountSyncData.settings.salesforceAccountIdentityMappingField || undefined;
  }

  get supportedFieldsGroupList() {
    let items = [];
    let suggestedItems = [];

    this.accountSyncData.accountMappingSupportedFields.forEach((field) => {
      let tooltipText = this.isSalesforceAttributeMappingField(field.name)
        ? 'This field is already used in an attribute mapping below'
        : null;

      let item = {
        text: field.name,
        value: field.name,
        tooltipText,
        isDisabled: this.isSalesforceAttributeMappingField(field.name),
      };

      if (
        this.suggestedFields['account_mapping_suggested_fields'] &&
        this.suggestedFields['account_mapping_suggested_fields'][field.name]
      ) {
        item['sortCount'] = this.suggestedFields['account_mapping_suggested_fields'][field.name];
        suggestedItems.push(item);
      } else {
        items.push(item);
      }
    });

    if (isPresent(suggestedItems)) {
      suggestedItems = suggestedItems.sort((a, b) => b['sortCount'] - a['sortCount']);
      return [
        { heading: 'Recommended', items: suggestedItems },
        { heading: 'All fields', items },
      ];
    } else {
      return [{ items }];
    }
  }

  get intercomAttributeMappingOptions() {
    let intercomAttributeMappingOptions = this.attributeService.crmCompanyAttributes.map(
      (attribute) =>
        attribute.asCrmAttributeMappingOption(
          ['company.remote_company_id'],
          this.attributeMappings,
          `companies`,
          `Account`,
        ),
    );

    let attributes = this.attributeService.crmCompanyAttributes.map(
      (attribute) => attribute.identifier,
    );

    if (this.selectedIntercomAttr && !attributes.includes(this.selectedIntercomAttr)) {
      intercomAttributeMappingOptions.push({
        text: this.selectedIntercomAttr.split('.').pop(),
        value: this.selectedIntercomAttr,
        count: `(Archived)`,
        tooltipText: 'This attribute has been archived and is no longer available',
        isDisabled: true,
      });
    }

    return intercomAttributeMappingOptions;
  }

  isSalesforceAttributeMappingField(field) {
    let sourceMapping = this.accountAttributeMappings.findBy('sourceAttributePath', field);
    let destinationMapping = this.accountAttributeMappings.findBy(
      'destinationAttributePath',
      field,
    );
    return (
      (sourceMapping && sourceMapping.direction === syncDirection.CrmtoIntercom) ||
      (destinationMapping && destinationMapping.direction === syncDirection.IntercomToCRM)
    );
  }

  @task({ enqueue: true })
  *saveSettings() {
    try {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'salesforce_settings',
        context: 'account_data',
      });

      yield this.accountSyncData.save();

      let notificationMessage = 'Your account data sync settings were updated.';
      this.notificationsService.notifyConfirmation(notificationMessage);
    } catch (e) {
      if (e.jqXHR?.responseJSON?.errors) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          "Something went wrong and we couldn't update your Salesforce settings",
        );
      }
    }
  }

  @action
  onMappingFieldChange(selectedItem) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'update_account_mapping',
      object: 'account_data_mapping_field',
      context: 'account_data',
    });

    this.accountSyncData.settings.salesforceAccountIdentityMappingField = selectedItem;
  }

  @action
  closeModal() {
    this.selectedMappingId = undefined;
    this.selectedIntercomAttr = undefined;
    this.selectedSalesforceAttr = undefined;
    this.selectedSyncDirection = undefined;
    this.selectedDisableEmptySourceSync = true;
    this.selectedDisableNonEmptyDestinationSync = false;
    this.showAttributeMappingModal = false;
  }

  @action
  openModal(row) {
    this.selectedMappingId = row.id;
    this.selectedIntercomAttr = row.intercomAttributePath;
    this.selectedSalesforceAttr = row.salesforceFieldPath;
    this.selectedSyncDirection = row.direction;
    this.selectedDisableEmptySourceSync = row.disableEmptySourceSync;
    this.selectedDisableNonEmptyDestinationSync = row.disableNonEmptyDestinationSync;
    this.showAttributeMappingModal = true;
  }

  @action
  removeAttributeMapping(attributeMapping) {
    this.accountSyncData.attributeMappings.removeFragment(attributeMapping);
  }
}
