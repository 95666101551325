/* import __COLOCATED_TEMPLATE__ from './account-lists.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import SettingsPanel from 'embercom/components/appstore/app-package/settings/salesforce-by-intercom/base/panel';
import { computed, action } from '@ember/object';
import { readOnly, map, mapBy } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { put } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default SettingsPanel.extend({
  intercomEventService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  init() {
    this._super(...arguments);
    this._setOriginalAccountListIds();
  },

  mappedAccountLists: computed('accountLists.[]', function () {
    let salesforceAccountLists = this.salesforceAccountLists;
    return this.accountLists.map((accountList) => {
      return salesforceAccountLists.findBy('id', accountList.listId);
    });
  }),

  selectableSalesforceAccountLists: computed('currentlySelectedAccountListIds.[]', function () {
    return this.salesforceAccountLists.filter(
      (salesforceAccountList) =>
        !this.currentlySelectedAccountListIds.includes(salesforceAccountList.id),
    );
  }),

  salesforceAccountListGroupListItems: map(
    'selectableSalesforceAccountLists',
    function (salesforceAccountList) {
      return { label: salesforceAccountList.id, text: salesforceAccountList.name };
    },
  ),

  accountListsChanged: computed('_originalAccountListIds.[]', 'mappedAccountLists.[]', function () {
    let originalAccountListIds = this._originalAccountListIds.sort();
    let currentAccountListIds = this.currentlySelectedAccountListIds.sort();
    return !_.isEqual(originalAccountListIds, currentAccountListIds);
  }),

  mappedAccountListsPresentInSalesforce: computed('mappedAccountLists.[]', function () {
    return this.mappedAccountLists.filter((list) => list !== undefined);
  }),

  currentlySelectedAccountListIds: mapBy('mappedAccountListsPresentInSalesforce', 'id'),

  accountListMissing: computed('mappedAccountLists.[]', function () {
    return this.mappedAccountLists.some((list) => list === undefined);
  }),

  saveAccountLists: task(function* () {
    try {
      this._notifyProcessing();
      yield put('/ember/salesforce_integrations/update_account_lists', {
        app_id: this.app.id,
        account_list_ids: this.currentlySelectedAccountListIds,
      });
      this._setOriginalAccountListIds();
      this._notifySuccess(`Account lists saved successfully.`);
    } catch (e) {
      this._notifyFailure("Something went wrong and we couldn't update your account lists");
    }
  }).drop(),

  _setOriginalAccountListIds() {
    let originalAccountListIds = this.accountLists.mapBy('listId');
    this.set('_originalAccountListIds', originalAccountListIds);
  },

  _trackEvent(object, action) {
    this.intercomEventService.trackAnalyticsEvent({ action, object });
  },

  addAccountList: action(function (salesforceAccountList) {
    this.accountLists.createFragment({ listId: salesforceAccountList.label });
    this._trackEvent(salesforceAccountList, 'created');
  }),

  actions: {
    removeAccountList(accountListId) {
      let accountList = this.accountLists.findBy('listId', accountListId);
      this.accountLists.removeFragment(accountList);
      this._trackEvent(accountList, 'deleted');
    },
  },
});
