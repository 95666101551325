/* import __COLOCATED_TEMPLATE__ from './query-builder.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import UrlPartialFilter from 'embercom/models/articles/search/url-partial-filter';

function initializeFilters(urlPartials) {
  return (urlPartials || []).map((value) => UrlPartialFilter.create({ value }));
}

export default class QueryBuilder extends Component {
  @service intl;

  @tracked filters = initializeFilters(this.args.urlPartials);
  @tracked inputValue = this.args.searchTerm || '';
  @tracked inputHasFocus = false;
  @tracked hasShownSearchIntent = false;
  @tracked _previousInputValue = this.args.searchTerm || '';

  get urlPartialFilters() {
    return this.filters.filter((filter) => filter.type === 'url-partial');
  }

  get showFilterBacking() {
    // As we auto focus on the search input when the page is loaded we only
    // want to show the backing once the user has actually interacted with
    // the input, currently this is on mouseover or when the start typing
    return this.inputHasFocus && this.hasShownSearchIntent;
  }

  get validFilters() {
    return [UrlPartialFilter];
  }

  get activeFilter() {
    let validFilterTypeSyntaxHash = {};
    this.validFilters.forEach((filter) => {
      let matcher = this.intl.t(filter.labelKey).toLowerCase();
      validFilterTypeSyntaxHash[`${matcher}:`] = filter;
    });
    for (let syntaxSymbol of Object.keys(validFilterTypeSyntaxHash)) {
      if (this.inputValue.toLowerCase().startsWith(syntaxSymbol)) {
        return validFilterTypeSyntaxHash[syntaxSymbol];
      }
    }
  }

  get tokens() {
    let value = this.inputValue;
    if (this.activeFilter) {
      let matcher = this.intl.t(this.activeFilter.labelKey).toLowerCase();
      value = value.substr(matcher.length + 1);
    }

    return value.trim().split(/\s+/);
  }

  get lastToken() {
    return this.tokens[this.tokens.length - 1] || '';
  }

  get lastTokenHasValue() {
    return this.lastToken.length > 0;
  }

  _doSearch() {
    this.hasShownSearchIntent = false;
    this._removeIncompleteFilters();
    this.args.performSearchTransition({
      search_term: this.inputValue.length > 0 ? this.inputValue : null,
      url_partials:
        this.urlPartialFilters.length > 0
          ? this.urlPartialFilters.map((filter) => filter.value)
          : null,
    });
  }

  _maybeCompleteFilter(allowIncomplete) {
    // Attempt to convert any complete filters from the text input into a filter
    // object, allowIncomplete ignores if theres a trailing space, we'll do this
    // when the user hits enter or clicks a dropdown item to complete the filter
    if (this.activeFilter) {
      if (this.lastTokenHasValue && (this.inputValue.endsWith(' ') || allowIncomplete)) {
        this.filters.pushObject(
          this.activeFilter.create({
            value: this.lastToken,
          }),
        );
        this.inputValue = '';
      }
    }
  }

  _removeIncompleteFilters() {
    if (this.activeFilter) {
      this.inputValue = '';
    }
  }

  _removeLastFilterIfNoInputValue() {
    if (!this._previousInputValue) {
      this.filters.popObject();
    }
  }

  @action
  setActiveFilter(filter) {
    if (!this.activeFilter && this.lastTokenHasValue) {
      this.filters.pushObject(
        filter.create({
          value: this.lastToken,
        }),
      );
      let remainingTokens = this.tokens.slice(0, this.tokens.length - 1);
      this.inputValue = remainingTokens.join(' ');
    } else {
      let label = this.intl.t(filter.labelKey);
      this.inputValue = `${label}: ${this.lastToken}`;
      if (this.lastTokenHasValue) {
        this._maybeCompleteFilter(true);
      }
    }
  }

  @action
  onKeyUp(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    let inputValueChanged =
      this._previousInputValue === undefined
        ? !!this.inputValue
        : this.inputValue !== this._previousInputValue;

    switch (keyCode) {
      case 27: // escape
        this.hasShownSearchIntent = false;
        break;
      case 13: // enter
        e.preventDefault();
        this._maybeCompleteFilter(true);
        this._doSearch();
        break;
      case 8: // backspace
        this._removeLastFilterIfNoInputValue();
        this._previousInputValue = this.inputValue;
        break;
      default:
        this.hasShownSearchIntent = inputValueChanged;
        this._previousInputValue = this.inputValue;
        this._maybeCompleteFilter();
    }
  }

  @action
  removeFilter(filter) {
    this.filters.removeObject(filter);
  }

  @action
  focusOn() {
    this.inputHasFocus = true;
  }

  @action
  focusOff() {
    this.inputHasFocus = false;
  }

  @action
  resetSearch() {
    this.filters = [];
    this.inputValue = '';
    this._doSearch();
  }

  @action
  loadFromAttributes() {
    this.filters = initializeFilters(this.args.urlPartials);
    this.inputValue = this.args.searchTerm || '';
    if (!this.args.knowledgeHub) {
      this._doSearch();
    }
  }
}
