/* import __COLOCATED_TEMPLATE__ from './added-examples.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class AddedExamples extends Component {
  @tracked isOpen = this.args.expandSelectedExamples;

  get hasExamples() {
    return this.args.exampleQuestions.length > 0;
  }

  get hasNoExamples() {
    return !this.hasExamples;
  }

  get containerClasses() {
    if (this.hasNoExamples) {
      return 'border-2 border-dashed border-neutral-border';
    } else if (this.args.questionTrainingState.isCompleted) {
      return 'border border-success-border';
    } else {
      return 'border border-neutral-border-emphasis';
    }
  }
}
