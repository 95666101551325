/* import __COLOCATED_TEMPLATE__ from './zendesk-import-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { get } from 'embercom/lib/ajax';
import type OauthState from 'embercom/models/articles/oauth-state';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type IntlService from 'ember-intl/services/intl';
import { registerDestructor } from '@ember/destroyable';
import {
  generateZendeskOAuthURL,
  isZendeskUrlInvalid,
  normalizeZendeskUrl,
} from 'embercom/utils/zendesk-url';

interface Args {
  onClose: () => void;
  helpCenterId: string;
}

interface ImportJobResponse {
  id: string;
  app_id: string;
  job_status: string;
  site_provider: string;
  site_url: string;
  job_finished_at?: string;
  created_at: string;
  mode: string;
}

export default class ZendeskImportModal extends Component<Args> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare openCenteredWindowService: $TSFixMe;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked url = '';

  @use runningImport = AsyncData<ImportJobResponse | undefined>(async () => {
    return get('/ember/article_import_jobs/current_import', {
      app_id: this.appService.app.id,
    });
  });

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.realTimeEventService.on('ImportStarted', this, 'handleStartedImport');

    registerDestructor(this, () => {
      this.realTimeEventService.off('ImportStarted', this, 'handleStartedImport');
    });
  }

  get helpCenter(): HelpCenterSite {
    let selectedHelpCenter = this.helpCenterService.allSites.find(
      (site: HelpCenterSite) => site.id === this.args.helpCenterId,
    );
    return selectedHelpCenter ?? this.helpCenterService.site;
  }

  get hasActiveApiImport() {
    return this.runningImport.isLoading || !!this.runningImport.value;
  }

  get isUrlInvalid() {
    return isZendeskUrlInvalid(this.url);
  }

  get shouldDisableImportButton() {
    return this.url.length === 0 || this.hasActiveApiImport || this.isUrlInvalid;
  }

  @action
  async startImport() {
    let url = normalizeZendeskUrl(this.url);
    if (!url) {
      return;
    }

    let oauthState: OauthState = this.store.createRecord('articles/oauth-state', {
      siteUrl: url,
      helpCenterId: this.args.helpCenterId,
    });
    await oauthState.save();

    let zendeskUrl = generateZendeskOAuthURL(url, oauthState.id, this.helpCenter);
    let window = this.openCenteredWindowService.setup(zendeskUrl, 800, 1280);
    if (window) {
      window.focus();
      await this.waitForWindowClose(window);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `import-zendesk-button`,
      section: 'helpcenter-collections.zendesk-import-modal',
      place: 'helpcenter-collections',
    });
  }

  private waitForWindowClose(window: any): Promise<void> {
    return new Promise((resolve) => {
      let checkInterval = setInterval(() => {
        if (window.closed) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 200);
    });
  }

  handleStartedImport() {
    let notificationMessage = this.intl.t('knowledge-hub.sync-modal.zendesk.import-started');
    this.notificationsService.notifyConfirmation(notificationMessage);

    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::ZendeskImportModal': typeof ZendeskImportModal;
    'articles/site/collections/tree/zendesk-import-modal': typeof ZendeskImportModal;
  }
}
