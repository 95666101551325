/* import __COLOCATED_TEMPLATE__ from './lead-account-ownership.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class MapData extends Component {
  @service store;
  @service appService;

  @tracked ownershipData = {};

  constructor() {
    super(...arguments);

    this.fetchOwnershipData.perform();
  }

  @task({ restartable: true })
  *fetchOwnershipData() {
    this.ownershipData = yield this.store.queryRecord(
      'appstore/app-package/salesforce/lead-account-ownership-data',
      { section_id: 'lead_account_ownership' },
    );
  }
}
