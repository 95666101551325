/* import __COLOCATED_TEMPLATE__ from './rate-limit.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import trustedStyle from '@intercom/pulse/lib/trusted-style';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class RateLimit extends Component {
  @service notificationsService;
  @tracked copiedSalesforceRateLimit = copy(this.args.syncState.salesforceRateLimit);

  get barColor() {
    if (this.shouldShowWarning) {
      return 'bg-notice-fill';
    }
    if (this.shouldShowError) {
      return 'bg-error-fill';
    }
    return 'bg-success-fill';
  }

  get barPercentage() {
    return (
      (this.args.syncState.salesforceRateLimit /
        this.args.syncState.salesforceIntercomMaxRateLimit) *
      100
    );
  }

  get barPercentageStyle() {
    return trustedStyle`width: ${this.barPercentage}%;`;
  }

  get shouldShowWarning() {
    return this.barPercentage < 90 && this.barPercentage > 60;
  }

  get shouldShowError() {
    return this.barPercentage >= 90;
  }

  get isInvalidRateLimit() {
    return this.copiedSalesforceRateLimit > this.args.syncState.salesforceIntercomMaxRateLimit;
  }

  @task({ enqueue: true })
  *saveSettings() {
    try {
      this.args.syncState.copiedSalesforceRateLimit = this.copiedSalesforceRateLimit;
      yield this.args.syncState.save();
      this.notificationsService.notifyConfirmation("We've successfully updated your rate limits.");
    } catch (e) {
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't update your Salesforce rate limit",
      );
    }
  }
}
