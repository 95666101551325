/* import __COLOCATED_TEMPLATE__ from './app-usages.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { notEmpty, or, readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  appService: service(),
  store: service(),

  init() {
    this._super(...arguments);
    this.fetchUsage.perform();
  },

  isLoading: readOnly('fetchUsage.isRunning'),
  isDevHub: false,
  usage: null,
  messagesUsingApp: readOnly('usage.messages'),
  botsUsingApp: computed(
    'usage.customBots',
    'usage.answers',
    'usage.operatorQualification',
    function () {
      if (!this.usage) {
        return [];
      }

      let customBots = this.usage.customBots || [];
      customBots = customBots.map((bot) => Object.assign({}, bot, { type: 'customBot' }));

      let answerBots = this.usage.answers || [];
      answerBots = answerBots.map((bot) => Object.assign({}, bot, { type: 'answerBot' }));

      let taskBots = this.usage.operatorQualification ? [{ type: 'taskBot' }] : [];

      return [...customBots, ...answerBots, ...taskBots];
    },
  ),
  adminsUsingAppInInboxSidebar: readOnly('usage.adminsUsingAppInInboxSidebar'),
  usedInInboxSidebar: notEmpty('adminsUsingAppInInboxSidebar'),

  usedInMessages: notEmpty('messagesUsingApp'),
  usedInBots: notEmpty('botsUsingApp'),
  usedInMessengerHome: readOnly('usage.messengerHome'),
  usedInConversations: readOnly('usage.conversations'),
  hasUsages: or(
    'usedInMessages',
    'usedInBots',
    'usedInMessengerHome',
    'usedInInboxSidebar',
    'usedInConversations',
  ),

  fetchUsage: task(function* () {
    let usage = this.isDevHub
      ? yield this.store.queryRecord('app-package-install-usage', {
          app_package_id: this.appPackage.id,
          app_id: this.app.id,
        })
      : yield this.store.findRecord(
          'app-package-install-usage',
          this.appPackage.get('appPackageInstallId'),
        );
    this.set('usage', usage);
  }).drop(),
});
