/* import __COLOCATED_TEMPLATE__ from './playground.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { deleteRequest, postRequest, putRequest, request } from 'embercom/lib/inbox/requests';
import { dropTask } from 'ember-concurrency-decorators';
import {
  PlaygroundTestRun,
  PlaygroundTestQuestion,
  type PlaygroundTestRunWireFormat,
  Status,
  type CustomerAnswerRating,
} from 'embercom/lib/fin-playground';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { taskFor } from 'ember-concurrency-ts';
import { cached } from 'tracked-toolbox';
import { use } from 'ember-resources/util/function-resource';
import FinPlaygroundTestExport from 'embercom/lib/fin-playground-test-export';
import type RouterService from '@ember/routing/router-service';
import type CsvService from 'embercom/services/csv';

export default class AiAgentPlayground extends Component {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare realTimeEventService: $TSFixMe;
  @service declare csv: CsvService;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  @tracked newQuestion = '';
  @tracked selectedQuestionIndex = 0;
  @tracked isAddingQuestions = false;
  @tracked showResetModal = false;
  @tracked showFinContentModal = false;
  @tracked showUploadCSVModal = false;
  @tracked isAutoBootstrapping = false;
  @tracked isUpdatingAnswerRating = false;

  @cached
  get latestTest() {
    return this.latestTestLoader.value ?? PlaygroundTestRun.build();
  }

  get selectedQuestion() {
    return this.latestTest.questions[this.selectedQuestionIndex];
  }

  get testHasQuestions() {
    return this.latestTest.questions.length > 0;
  }

  get hasQuestionsRunning() {
    return this.latestTest.questions.some((q) => q.status === Status.Running);
  }

  get deductionRequiredToAddQuestion(): number {
    if (!this.latestTest.hasReachedQuestionLimit) {
      return 0;
    }

    return 1 + this.latestTest.questions.length - this.latestTest.questionLimit;
  }

  get isStandaloneApp() {
    return this.appService.app.canUseFinStandalone;
  }

  @use private latestTestLoader = AsyncData<PlaygroundTestRun>(async () => {
    let response = await request(`/ember/fin_playground/test?app_id=${this.appService.app.id}`);
    return response.ok ? PlaygroundTestRun.deserialize(await response.json()) : null;
  });

  @action
  openFinContentModal() {
    this.showFinContentModal = true;
  }

  @action
  resetTest() {
    this.showResetModal = false;
    this.latestTestLoader.update(PlaygroundTestRun.build());
    this.selectedQuestionIndex = 0;

    taskFor(this._addQuestionsTask).perform([]);
  }

  saveQuestions(list: string[], source: string) {
    let questions = list
      .uniq()
      .filter((q) => q.trim().length > 0 && !this.latestTest.hasQuestion(q))
      .map((q) => new PlaygroundTestQuestion(undefined, q));

    if (questions.length === 0) {
      return;
    }

    this.latestTest.addQuestions(questions);
    taskFor(this._addQuestionsTask).perform(questions);

    this.trackAddingNewQuestions(questions.length, source);
  }

  @action
  addQuestionsFromCsvFile(list: string[]) {
    return this.saveQuestions(list, 'upload_csv_modal');
  }

  @action
  addQuestionsUsingForm(list: string[]) {
    return this.saveQuestions(list, 'add_questions_form');
  }

  @action
  runQuestions() {
    taskFor(this._runTestTask).perform(this.latestTest.pendingQuestions);
  }

  @action
  runSelectedQuestion() {
    if (!this.selectedQuestion?.id) {
      return;
    }

    this.latestTest.setQuestionState(this.selectedQuestion.id, Status.Running);

    taskFor(this._runTestTask).perform([this.selectedQuestion]);
  }

  @action
  removeQuestion(question_id?: number) {
    if (!question_id) {
      return;
    }

    taskFor(this._removeQuestionTask).perform(question_id);

    if (this.selectedQuestionIndex === question_id) {
      this.selectedQuestionIndex = 0;
    }
  }

  @action
  autoBootstrap() {
    taskFor(this._autoBootstrapQuestionsTask).perform();
  }

  @action
  selectQuestion(questionText: string) {
    this.selectedQuestionIndex =
      this.latestTest.questions.findIndex((q) => q.questionText === questionText) || 0;
  }

  @action
  async handleAnswerGenerated(e: { client_assigned_uuid: string }) {
    if (this.latestTest.clientAssignedUuid === e.client_assigned_uuid) {
      this.latestTestLoader.reload();
    }
  }

  @action
  updateQuestionRating(questionId: number, rating: CustomerAnswerRating) {
    this.isUpdatingAnswerRating = true;

    taskFor(this._updateQuestionTask).perform(questionId, rating);
  }

  @action
  downloadTestResults() {
    let { data, fileName } = new FinPlaygroundTestExport(
      this.appService.app,
      this.intl,
      this.latestTest,
      this.router,
    );

    this.csv.export(data, {
      fileName,
      withSeparator: false,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'downloaded_results',
      object: 'playground',
    });
  }

  @dropTask
  *_addQuestionsTask(questions: PlaygroundTestQuestion[]) {
    if (questions.length === 0) {
      return;
    }

    this.realTimeEventService.subscribeTopics([
      `fin-playground/${this.latestTest.clientAssignedUuid}`,
    ]);

    this.realTimeEventService.on('FinPlaygroundQuestionResponse', this, 'handleAnswerGenerated');

    this.latestTest.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/add_questions?app_id=${this.appService.app.id}&run_questions=true`,
      {
        client_assigned_uuid: this.latestTest.clientAssignedUuid,
        questions: questions.map((q) => ({ question_text: q.questionText })),
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
  }

  @dropTask
  *_removeQuestionTask(question_id: number) {
    let response = (yield deleteRequest(
      `/ember/fin_playground/${this.latestTest.id}/delete_question?app_id=${this.appService.app.id}`,
      {
        question_id,
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
  }

  @dropTask
  *_updateQuestionTask(questionId: number, customerAnswerRating: CustomerAnswerRating) {
    let response = (yield putRequest(
      `/ember/fin_playground/${this.latestTest.id}/update_question?app_id=${this.appService.app.id}`,
      {
        question_id: questionId,
        customer_answer_rating: customerAnswerRating,
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));

    this.isUpdatingAnswerRating = false;
  }

  @dropTask
  *_runTestTask(questions?: PlaygroundTestQuestion[]) {
    this.realTimeEventService.subscribeTopics([
      `fin-playground/${this.latestTest.clientAssignedUuid}`,
    ]);

    this.realTimeEventService.on('FinPlaygroundQuestionResponse', this, 'handleAnswerGenerated');

    this.latestTest.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/${this.latestTest.id}/run?app_id=${this.appService.app.id}`,
      {
        question_ids: questions?.map((q) => q.id),
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
  }

  @dropTask
  *_autoBootstrapQuestionsTask() {
    this.isAutoBootstrapping = true;
    try {
      let response = (yield postRequest(
        `/ember/fin_playground/auto_bootstrap_questions?app_id=${this.appService.app.id}`,
        {
          client_assigned_uuid: this.latestTest.clientAssignedUuid,
        },
      )) as Response;
      this.isAutoBootstrapping = false;
      let json = (yield response.json()) as PlaygroundTestRunWireFormat;
      this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
      taskFor(this._runTestTask).perform(this.latestTest.pendingQuestions);

      this.trackAddingNewQuestions(this.latestTest.pendingQuestions.length, 'ai_bootstrap');
    } finally {
      this.isAutoBootstrapping = false;
    }
  }

  trackAddingNewQuestions(new_question_count: number, source: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'added_questions',
      object: 'playground',
      new_question_count,
      source,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground': typeof AiAgentPlayground;
  }
}
