/* import __COLOCATED_TEMPLATE__ from './task-creation.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { syncDirection } from 'embercom/models/crm/attribute-mapping-constants';

const UNSUPPORTED_CONVERSATION_ATTRIBUTES = [
  'conversation.conversation_started_at',
  'conversation.next_breach_time',
  'conversation.tag_ids',
];

export default class TaskCreation extends Component {
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service store;
  @service attributeService;

  @tracked caseSetupData = {};
  @tracked showAttributeMappingModal = false;
  @tracked selectedMappingId;
  @tracked selectedIntercomAttr;
  @tracked selectedSalesforceAttr;
  @tracked selectedSyncDirection;
  @tracked selectedDisableEmptySourceSync = true;
  @tracked selectedDisableNonEmptyDestinationSync = false;
  @tracked selectedCaseRecordType;

  app = this.appService.app;

  customNotificationKey = 'salesforce-settings-notification';

  constructor() {
    super(...arguments);
    this.fetchCaseSetupData.perform();
    this.store.findAll('conversation-attributes/descriptor');
  }

  @task({ restartable: true })
  *fetchCaseSetupData() {
    this.caseSetupData = yield this.store.queryRecord(
      'appstore/app-package/salesforce/case-setup-data',
      { section_id: 'case_setup' },
    );
  }

  get inboxRulesBannerLink() {
    return 'apps.app.settings.inbox-rules.list';
  }

  get caseFieldOptions() {
    let usedFields = [
      ...this.caseSetupData.requiredCaseFields,
      ...this.caseSetupData.settings.caseFields,
    ];

    return this.caseSetupData.caseFields.reduce((result, caseField) => {
      if (!caseField.metadata.updateable) {
        return result;
      }

      if (usedFields.includes(caseField.name)) {
        return result;
      }

      return [
        ...result,
        {
          text: caseField.metadata.label,
          value: caseField.name,
        },
      ];
    }, []);
  }

  get caseFieldGroupList() {
    return [{ items: this.caseFieldOptions }];
  }

  get caseCreationFields() {
    let caseFields = this.caseSetupData.caseFields;
    let requiredFields = this.caseSetupData.requiredCaseFields.map((name) => ({
      ...caseFields.find((field) => field.name === name),
      required: true,
    }));
    let customFields = this.caseSetupData.settings.caseFields.map((name) =>
      caseFields.find((field) => field.name === name),
    );
    return [...requiredFields, ...customFields];
  }

  get salesforceDropdownFields() {
    return this.caseSetupData.salesforceCaseFields.map((field) =>
      field.asCrmAttributeMappingOption([], this.caseAttributeMappings, 'conversation', 'Case'),
    );
  }

  get caseAttributeMappings() {
    return this.caseSetupData.attributeMappings.filter((attributeMapping) => {
      return attributeMapping.destinationObjectType === 'Case';
    });
  }

  get intercomAttributeMappingOptions() {
    return this.attributeService.crmConversationAttributes
      .filter((attribute) => !UNSUPPORTED_CONVERSATION_ATTRIBUTES.includes(attribute.identifier))
      .map((attribute) =>
        attribute.asCrmAttributeMappingOption(
          [],
          this.caseAttributeMappings,
          'conversations',
          'Case',
        ),
      );
  }

  get disableCRTOption() {
    let caseRecordTypes = this.caseSetupData.salesforceCaseRecordTypes;
    return !caseRecordTypes.filter((recordType) => recordType.available).length;
  }

  get isSaveButtonDisabled() {
    let result = !this.caseSetupData.hasDirtyAttributes;
    this.args.setSettingsAreDirty(!result);

    return result;
  }

  get dropdownData() {
    let recordTypes = [];
    this.caseSetupData.salesforceCaseRecordTypes.forEach((crt) => {
      recordTypes.push({
        text: crt.data.name,
        value: crt.data.recordTypeId,
        isSelected:
          this.caseSetupData.settings.automaticCaseCreationRecordType === crt.data.recordTypeId,
      });
    });
    if (recordTypes.length > 0) {
      recordTypes.unshift({
        text: 'None',
        value: null,
        isSelected: this.caseSetupData.settings.automaticCaseCreationRecordType === null,
      });
    }
    return recordTypes;
  }

  get intercomToCrmDirection() {
    return syncDirection.IntercomToCRM;
  }

  @action
  setSelectedCaseRecordType(record) {
    this.caseSetupData.settings.automaticCaseCreationRecordType = record;
    this.selectedCaseRecordType = record;
  }

  @action
  addField(name) {
    this.caseSetupData.settings.caseFields = [...this.caseSetupData.settings.caseFields, name];
  }

  @action
  removeField(name) {
    this.caseSetupData.settings.caseFields = this.caseSetupData.settings.caseFields.filter(
      (caseField) => caseField !== name,
    );
  }

  @action
  toggleAutomaticTaskCreation() {
    this.caseSetupData.settings.automaticTaskCreation =
      !this.caseSetupData.settings.automaticTaskCreation;
  }

  @action
  toggleCreateCaseOnConversationClose() {
    this.caseSetupData.settings.createCaseOnConversationClose =
      !this.caseSetupData.settings.createCaseOnConversationClose;
  }

  @action
  toggleCreateCaseOnConversationCreate() {
    this.caseSetupData.settings.createCaseOnConversationCreate =
      !this.caseSetupData.settings.createCaseOnConversationCreate;
  }

  @task({ enqueue: true })
  *saveSettings() {
    try {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'salesforce_settings',
        context: 'case_data',
      });
      yield this.caseSetupData.save();

      let notificationMessage = 'Your settings were updated.';
      this.notificationsService.notifyConfirmation(notificationMessage);
    } catch (e) {
      if (e.jqXHR?.responseJSON?.errors) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          "Something went wrong and we couldn't update your Salesforce settings",
        );
      }
    }
  }

  @action
  openModal(row) {
    this.selectedMappingId = row.id;
    this.selectedIntercomAttr = row.isIntercomAttributeMissing
      ? undefined
      : row.intercomAttributePath;
    this.selectedSalesforceAttr = row.isSalesforceFieldMissing
      ? undefined
      : row.salesforceFieldPath;
    this.selectedSyncDirection = row.direction;
    this.selectedDisableEmptySourceSync = row.disableEmptySourceSync;
    this.selectedDisableNonEmptyDestinationSync = row.disableNonEmptyDestinationSync;
    this.showAttributeMappingModal = true;
  }

  @action
  closeModal() {
    this.selectedMappingId = undefined;
    this.selectedIntercomAttr = undefined;
    this.selectedSalesforceAttr = undefined;
    this.selectedSyncDirection = undefined;
    this.selectedDisableEmptySourceSync = true;
    this.selectedDisableNonEmptyDestinationSync = false;
    this.showAttributeMappingModal = false;
  }

  @action
  mapNewAttribute() {
    this.selectedSyncDirection = 0;
    this.showAttributeMappingModal = true;
  }

  @action
  removeAttributeMapping(attributeMapping) {
    this.caseSetupData.attributeMappings.removeFragment(attributeMapping);
  }
}
