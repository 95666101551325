/* import __COLOCATED_TEMPLATE__ from './version-card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ArticleContent from 'embercom/models/articles/article-content';

interface Args {
  isCurrent: boolean;
  isLive: boolean;
  version: any;
  onSelection: () => void;
  isFirstVersion: boolean;
  isLatestVersion: boolean;
  article: ArticleContent;
  selectedVersion: any;
  closeSideDrawer: () => void;
  onVersionRestore: () => void;
}

export default class VersionCard extends Component<Args> {
  @service intl!: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  get selectedVersionCardBackgroundColorClass() {
    return this.args.isCurrent ? 'educate__article__version__background-color-grey' : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Show::Content::VersionCard': typeof VersionCard;
    'articles/show/content/version-card': typeof VersionCard;
  }
}
