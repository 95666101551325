/* import __COLOCATED_TEMPLATE__ from './import-brand.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import isValidUrl from 'embercom/lib/url-validator';
import { sanitizedDomainLink } from 'embercom/lib/onboarding/brand-fetch-functions';
import { put } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { type UploadHelper } from 'embercom/objects/media/media-helper';

interface Args {
  site: HelpCenterSite;
  uploadHelper: UploadHelper;
}

export default class ImportBrand extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked invalidBrandUrl = false;
  @tracked brandUrl = '';
  @tracked invalidURLErrorMessage = '';

  @tracked openBrandSectionId = '';

  get app() {
    return this.appService.app;
  }

  get siteBrand() {
    return this.args.site.customizationOptions.global.brand;
  }

  get isAutoGenerateRunning() {
    return taskFor(this.autoGenerateStyles).isRunning;
  }

  get brandImportButtonText() {
    if (this.isAutoGenerateRunning) {
      return this.intl.t('articles.settings.stylings.import-your-brand.actions.importing');
    }

    return this.intl.t('articles.settings.stylings.import-your-brand.actions.import');
  }

  @action
  clearBrand() {
    this.args.site.customizationOptions.global.brand.colors = [];
    this.args.site.customizationOptions.global.brand.websiteUrl = '';
    this._trackEvent('brand_cleared');
  }

  @dropTask
  *autoGenerateStyles() {
    if (!this._validateBrandUrl()) {
      return;
    }

    let sanitizedUrl = sanitizedDomainLink(this.brandUrl);

    try {
      let siteWithBrand: unknown = yield put('/ember/help_center_sites/import_brand', {
        app_id: this.app.id,
        site_id: this.args.site.id,
        website_url: sanitizedUrl,
      });
      this._trackEvent('brand_import_success');

      // Reset the brandUrl so that the input is cleared
      this.brandUrl = '';
      // Fixes a bug where clearing and re-importing the brand without refresh would not update the brand
      this.args.site.rollbackAttributes();

      // Push the site object with the new brand
      this.store.pushPayload('help-center-site', {
        'help-center-site': siteWithBrand,
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.settings.stylings.import-your-brand.success', {
          domain: sanitizedUrl,
        }),
      );
    } catch (error) {
      this._trackEvent('brand_import_failed');
      console.error('Could not fetch brand', error);

      if (error?.jqXHR?.status === 429) {
        this.notificationsService.notifyError(
          this.intl.t('articles.settings.stylings.import-your-brand.too-many-requests'),
        );
        return;
      }

      this.notificationsService.notifyError(
        this.intl.t('articles.settings.stylings.import-your-brand.error', {
          domain: sanitizedUrl,
        }),
      );
    }
  }

  async _updateImage(imageUrl: string, imgType: string) {
    if (imageUrl) {
      let imgResponse: Response = await fetch(imageUrl);
      let imgAsBlob: Blob = await imgResponse.blob();
      await this.args.uploadHelper.getMediaHelper(imgType).readFile(imgAsBlob);
    }
  }

  _validateBrandUrl() {
    this.invalidBrandUrl = !isValidUrl(this.brandUrl);
    if (this.invalidBrandUrl) {
      this.invalidURLErrorMessage = this.intl.t(
        'articles.settings.stylings.import-your-brand.invalid-url',
      );
    }
    return !this.invalidBrandUrl;
  }

  _trackEvent(action: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'help_center_site',
      place: 'help_center_site_styling',
      app_id: this.app.id,
      site_id: this.args.site.id,
      brand_url: this.brandUrl,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::ImportBrand': typeof ImportBrand;
    'articles/site/settings/styling/import-brand': typeof ImportBrand;
  }
}
