/* import __COLOCATED_TEMPLATE__ from './radial-progress-indicator.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import PALETTE from '@intercom/pulse/lib/palette';

export default class RadialProgressIndicator extends Component {
  size = 36;
  strokeWidth = 5;

  get cx() {
    return this.size / 2;
  }

  get cy() {
    return this.size / 2;
  }

  get radius() {
    return this.size / 2 - this.strokeWidth / 2;
  }

  get circleCircumference() {
    return 2 * Math.PI * this.radius;
  }

  get progressCircumference() {
    if (this.args.progressCounter >= this.args.progressCompletionAt) {
      return 0;
    }

    let rateOfCompletion = this.args.progressCounter / this.args.progressCompletionAt;
    let circleCircum = this.circleCircumference;

    if (rateOfCompletion === 0) {
      return circleCircum - 0.01;
    }

    let currentProgress = circleCircum - circleCircum * rateOfCompletion;
    return currentProgress;
  }

  get strokeColor() {
    let progressCounter = this.args.progressCounter;

    if (progressCounter === 0) {
      return PALETTE['text-muted'];
    }

    if (progressCounter >= this.args.progressSuccessAt) {
      return PALETTE['success-fill'];
    }

    return PALETTE['text-link'];
  }
}
