/* import __COLOCATED_TEMPLATE__ from './collection-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TreeItem } from 'embercom/objects/tree-list';
import { MAX_LEVEL } from './collection-tree-helper';
import { type Collection, type DataObject, DataTypes } from './types';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import type RouterService from '@ember/routing/router-service';
import type ArticleGroup from 'embercom/models/articles/article-group';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  item: TreeItem;
}

export default class CollectionItem extends Component<Args> {
  @tracked openArticlePicker = false;
  @tracked loading = false;
  @tracked addableArticles: Array<ArticleMultilingual> = [];
  @tracked selectedArticles: Array<ArticleMultilingual> = [];
  @tracked collectionToEdit: ArticleGroup | undefined;

  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  get collectionDataObject(): DataObject {
    return this.args.item.dataObject;
  }

  get app() {
    return this.appService.app;
  }

  get locale() {
    return this.collectionDataObject.locale();
  }

  get collection() {
    return this.collectionDataObject.content as Collection;
  }

  get collectionStatus() {
    if (!this.collection.name) {
      return 'missing-title';
    }
    if (!this.contentHasPublishedChild(this.args.item)) {
      return 'missing-article';
    }
    return 'published';
  }

  get setupModalContext() {
    let context = {
      eventSource: 'collection_item',
      collection: this.collectionToEdit,
      localeId: this.locale,
    };
    return context;
  }

  contentHasPublishedChild(item: TreeItem): boolean {
    if (!item.children?.length) {
      return false;
    }
    return item.children.any((child) => {
      let cData = child.dataObject;
      let articleLocale = cData.locale && cData.locale();
      let selectedLocalizedArticle =
        articleLocale && cData.content?.locales && cData.content?.locales[articleLocale];
      return (
        selectedLocalizedArticle?.state === 'published' || this.contentHasPublishedChild(child)
      );
    });
  }

  @action
  async onAdd(dataType: DataTypes) {
    if (dataType === DataTypes.collection && this.collectionDataObject.addCollection) {
      await this.collectionDataObject.addCollection();
    } else if (dataType === DataTypes.article && this.collectionDataObject.listAddableArticles) {
      this.openArticlePicker = true;
      this.loading = true;
      let addableArticles = await this.collectionDataObject.listAddableArticles();
      this.addableArticles = addableArticles.reject(
        (article: ArticleMultilingual) => article.readOnly,
      );
      this.selectedArticles = [];
      this.loading = false;
    }
  }

  @action
  closeArticlePicker() {
    this.openArticlePicker = false;
  }

  @action
  createArticleInCollection() {
    if (this.app.canUseInContextEditor) {
      this.knowledgeHubService.createContent(EntityType.Article, {
        collectionId: this.collection.id,
        contentLocale: this.collectionDataObject.locale?.(),
      });

      this.openArticlePicker = false;
    } else {
      this.router.transitionTo('apps.app.articles.articles.new', {
        queryParams: {
          collection_id: this.collection.id,
          locale: this.collectionDataObject.locale?.(),
        },
      });
      this.openArticlePicker = false;
    }
  }

  get isAddToCollectionButtonDisabled() {
    return this.loading || !this.selectedArticles.length;
  }

  get addToCollectionButtonLabel() {
    return this.selectedArticles.length
      ? this.intl.t('articles.collections.add_article.button', {
          count: this.selectedArticles.length,
        })
      : this.intl.t('articles.collections.add_article.default_button');
  }

  @action
  async addSelectedArticleToCollection() {
    this.loading = true;
    if (this.collectionDataObject.addArticlesToCollection) {
      await this.collectionDataObject.addArticlesToCollection(this.selectedArticles);
    }
    this.loading = false;
    this.openArticlePicker = false;
  }

  @action
  async removeCollection() {
    if (this.collectionDataObject.deleteCollection) {
      await this.collectionDataObject.deleteCollection();
    }
  }

  @action
  async saveCollection() {
    if (this.collectionDataObject.saveCollection) {
      await this.collectionDataObject.saveCollection();
    }
  }

  // Toggle isExpanded unless the click is on an element preventing expansion
  @action
  toggleIsExpanded(event: PointerEvent) {
    let preventExpandElements = Array.from(
      document.getElementsByClassName('prevent-colleciton-tree-expand'),
    );
    let target = event.target;

    if (!(target instanceof Element)) {
      return;
    }

    for (let element of preventExpandElements) {
      if (element.contains(target)) {
        return;
      }
    }

    this.args.item.isExpanded = !this.args.item.isExpanded;
  }

  @action
  setCollectionToEdit() {
    this.collectionToEdit = this.collectionDataObject.content as unknown as ArticleGroup;
  }

  get contents() {
    if (!this.app.showRedesignedCollectionTable) {
      return this.oldContents;
    }

    let addToCollectionItems = [
      {
        text: this.intl.t('articles.collections.actions.add_to_collection.new_article'),
        icon: 'article',
        onSelectItem: async () => {
          this.onAdd(DataTypes.article);
        },
        isDestructive: false,
        isDisabled: this.collection.read_only,
      },
    ];
    if (this.collectionDataObject.level < MAX_LEVEL) {
      addToCollectionItems.unshift({
        text: this.intl.t('articles.collections.actions.add_to_collection.new_subcollection'),
        icon: 'collection',
        onSelectItem: async () => {
          this.onAdd(DataTypes.collection);
        },
        isDestructive: false,
        isDisabled: this.collection.read_only,
      });
    }

    return [
      {
        items: [
          {
            text: this.intl.t('articles.collections.actions.edit_details'),
            icon: 'edit',
            onSelectItem: this.setCollectionToEdit,
            isDisabled: this.collection.read_only,
          },
          {
            text: this.intl.t('articles.collections.actions.delete'),
            icon: 'trash',
            onSelectItem: async () => {
              this.removeCollection();
            },
            isDestructive: true,
            isDisabled: this.collection.read_only,
          },
        ],
      },
      {
        heading: this.intl.t('articles.collections.actions.add_to_collection_group_heading'),
        items: addToCollectionItems,
      },
    ];
  }

  get oldContents() {
    let items = [
      {
        text: this.intl.t('articles.collections.actions.add_articles'),
        icon: 'article',
        onSelectItem: async () => {
          this.onAdd(DataTypes.article);
        },
        isDestructive: false,
        isDisabled: this.collection.read_only,
      },
    ];
    if (this.collectionDataObject.level < MAX_LEVEL) {
      items.push({
        text: this.intl.t('articles.collections.actions.add_child_collection'),
        icon: 'collection',
        onSelectItem: async () => {
          this.onAdd(DataTypes.collection);
        },
        isDestructive: false,
        isDisabled: this.collection.read_only,
      });
    }
    items.push({
      text: this.intl.t('articles.collections.actions.delete'),
      icon: 'trash',
      onSelectItem: async () => {
        this.removeCollection();
      },
      isDestructive: true,
      isDisabled: this.collection.read_only,
    });

    return [
      {
        items,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::CollectionItem': typeof CollectionItem;
    'articles/site/collections/tree/collection-item': typeof CollectionItem;
  }
}
