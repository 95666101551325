/* import __COLOCATED_TEMPLATE__ from './whatsapp-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class WhatsappCard extends Component {
  @service store;

  @tracked appPackage;

  constructor() {
    super(...arguments);
    this.loadAppPackage.perform();
  }

  @task({ drop: true })
  *loadAppPackage() {
    this.appPackage = yield this.store.findRecord('appstore/app-package', 'whatsapp-fqam');
  }

  get isCompact() {
    return this.args.compact;
  }
}
