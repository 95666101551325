/* import __COLOCATED_TEMPLATE__ from './tag.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { equal, not, readOnly, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import BulkActionModal from 'embercom/controllers/mixins/users-and-companies/bulk-action-modal';
import ajax from 'embercom/lib/ajax';
import pluralise from 'embercom/lib/inflector';
import $ from 'jquery';
import { action } from '@ember/object';

export default Component.extend(BulkActionModal, {
  tagName: '',
  store: service(),
  intercomEventService: service(),
  notificationsService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  tagSorting: ['name'],
  sortedTags: sort('app.tags', 'tagSorting'),
  tagEndpoint: '/ember/users/bulk_tag.json',
  modalTarget: 'user',
  modalTargetPlural: computed('model.hasCount', 'model.count', 'modalTarget', function () {
    let targetCount = this.get('model.hasCount') ? this.get('model.count') : 0;
    return pluralise(this.modalTarget, targetCount);
  }),
  modalAction: 'tag',

  init() {
    this._super(...arguments);
    this.reset();
  },

  reset() {
    this.setProperties({
      newTagName: null,
      existingTagName: this.get('sortedTags.firstObject.name'),
      newTag: 'true',
      isSaving: false,
      hasErrored: false,
    });
  },
  isNew: equal('newTag', 'true'),
  isExisting: not('isNew'),
  addNewTagToApp(tag) {
    if (this.isNew) {
      this.store.pushPayload({ tag });
      let tagRecord = this.store.peekRecord('tag', tag.id);
      this.get('app.tags').pushObject(tagRecord);
      this._trackEvent('tag-created', 'created', 'user-company', 'user-company-list', 'growth');
    }
  },
  notificationMessage() {
    let targetCount = this.get('model.hasCount')
      ? this.get('model.count')
      : 'An unknown number of ';
    if (
      this.get('model.hasCount') &&
      this.get('model.count') < 200 &&
      this.get('model.isNotSelectAllMode')
    ) {
      return `${targetCount} ${this.modalTargetPlural} tagged with ${this.selectedTagName}`;
    } else {
      return `${targetCount} ${this.modalTargetPlural} will be tagged with ${this.selectedTagName}. At peak times this could take a few hours`;
    }
  },
  selectedTagName: computed('isNew', 'newTagName', 'existingTagName', function () {
    if (this.isNew) {
      let tagName = this.newTagName || '';
      return tagName.trim().replace(',', '');
    } else {
      return this.existingTagName;
    }
  }),
  isInvalid: not('selectedTagName'),
  makeTagVisibleToAdmin(tag) {
    if (this.isNew) {
      this.admin.addTagIdsToAdminsVisibleTagIds([tag.id]);
    }
  },
  handleSuccess(tag, selectedIds) {
    this.addNewTagToApp(tag);
    this.makeTagVisibleToAdmin(tag);
    this.notificationsService.notifyConfirmation(this.notificationMessage());
    this.reset();
    this._trackEvent(
      'user-company-tagged',
      'tagged',
      'user-company',
      'user-company-list',
      'growth',
      { user_company_ids: selectedIds },
    );
  },
  handleError(jqXHR) {
    this.processBulkActionFailure(jqXHR, 'isSaving');
  },
  _trackEvent(name, action, object, place, owner, additionalMetadata) {
    let metadata = {
      action,
      object,
      place,
      owner,
    };
    Object.assign(metadata, additionalMetadata);
    this.intercomEventService.trackEvent(name, metadata);
  },
  closeModal: action(function () {
    this.modalService.closeModal();
  }),
  onSubmit: action(function () {
    this.set('isSaving', true);
    this.set('hasErrored', false);
    let selectedIds = this.get('model.selectedIDs');
    ajax({
      url: this.tagEndpoint,
      type: 'POST',
      data: JSON.stringify(
        $.extend(this.model.toApi(), {
          app_id: this.get('app.id'),
          tag_name: this.selectedTagName,
        }),
      ),
    }).then((tag) => {
      this.handleSuccess(tag, selectedIds);
    }, this.handleError.bind(this));
  }),
});
