/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  notificationsService: service(),
  customNotificationKey: 'salesforce-settings-notification',

  _notifyProcessing() {
    this.notificationsService.notifyLoading('Saving...', -1, this.customNotificationKey);
  },
  _notifySuccess(message) {
    this.notificationsService.removeNotification(this.customNotificationKey);
    this.notificationsService.notifyConfirmation(message);
  },
  _notifyFailure(message) {
    this.notificationsService.removeNotification(this.customNotificationKey);
    this.notificationsService.notifyError(message);
  },
});
