/* import __COLOCATED_TEMPLATE__ from './fin-language-style-settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type ToneOfVoiceSettings from 'embercom/models/ai-agent/tone-of-voice-settings';

interface SettingsArgs {
  aiAgentToneOfVoiceSettings: ToneOfVoiceSettings;
  body: any;
}

export default class FinLanguageStyleSettings extends Component<SettingsArgs> {
  @service declare intl: IntlService;

  @tracked selectedLanguageStyle: string;

  constructor(owner: unknown, args: SettingsArgs) {
    super(owner, args);
    this.selectedLanguageStyle = this.args.aiAgentToneOfVoiceSettings.aiLanguageStyle;
  }

  get availableLanguageStyles() {
    return [
      {
        value: 'let_fin_decide',
        text: this.intl.t('ai-agent.settings.language-styles.let-fin-decide.name'),
      },
      {
        value: 'informal',
        text: this.intl.t('ai-agent.settings.language-styles.informal.name'),
      },
      {
        value: 'formal',
        text: this.intl.t('ai-agent.settings.language-styles.formal.name'),
      },
    ];
  }

  get selectedStyleDescription() {
    if (this.selectedLanguageStyle === 'let_fin_decide') {
      return this.intl.t('ai-agent.settings.language-styles.let-fin-decide.description');
    } else if (this.selectedLanguageStyle === 'informal') {
      return this.intl.t('ai-agent.settings.language-styles.informal.description');
    } else if (this.selectedLanguageStyle === 'formal') {
      return this.intl.t('ai-agent.settings.language-styles.formal.description');
    }
    return '';
  }

  @action
  setLanguageStyle(value: string) {
    this.args.aiAgentToneOfVoiceSettings.aiLanguageStyle = value;
    this.selectedLanguageStyle = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::FinLanguageStyleSettings': typeof FinLanguageStyleSettings;
    'ai-agent/fin-language-style-settings': typeof FinLanguageStyleSettings;
  }
}
