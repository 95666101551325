/* import __COLOCATED_TEMPLATE__ from './uninstall-button.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  showUninstallModal: false,
  showReinstallModal: false,
  init() {
    this._super(...arguments);
    this.set('groupList', [
      {
        items: [
          {
            text: this.intl.t('appstore.app-package.uninstall-reinstall.reinstall'),
            value: 'reinstall',
          },
        ],
      },
    ]);
  },
  groupList: [],
});
