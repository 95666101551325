/* import __COLOCATED_TEMPLATE__ from './sync-status.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';

export default class SyncStatus extends Component {
  get lastSyncDate() {
    if (this.args.lastSyncDate) {
      let date = new Date(1970, 0, 1);
      date.setSeconds(this.args.lastSyncDate);
      return date;
    }

    return null;
  }
}
